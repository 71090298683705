<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>Welcome to Charter!</p>

    <p>
      Charter optimises your ad budgets cross-platform using our innovative
      AI-powered algorithm. You decide how much per month to spend on ads,
      select your platforms (Meta, Google, TikTok etc.) and Charter will
      automatically allocate that spend across those platforms.
    </p>

    <p>
      What makes Charter’s algorithm different? Each platform has their own
      algorithms which work really well - but only within that platform. Meta’s
      algorithm is never going to say “hey actually, your ads on Google are
      doing better than Meta at the moment, you should put more budget there”.
      In order to get the most out of your advertising budget, you’ve got to
      find the platform that’s most effective for you - and adjust as this
      changes over time. That’s why we created Charter.
    </p>

    <p>
      There’s a few companies out there solving this by using “predictive”
      solutions, often shoving “machine learning” where it doesn’t need to be
      and assuming past performance can predict future performance. Our solution
      is super lightweight, agile, and doesn’t require any “training” on past
      data. Charter works in the moment, adjusting live to maximise your
      results.
    </p>

    <p>
      By using Charter, you’ll save countless hours wasted spent analysing your
      ads and trying to determine budget changes. You’ll also have better
      performing ads resulting in delighted clients. Happier clients + freed up
      hours = capacity to go get more happy clients! Charter saves you time and
      gets you more money.
    </p>
  </div>
</template>
