<script setup lang="ts">
import { watchOnce } from "@vueuse/core";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import AdChannelIcon from "@/app/ad-channel-icon.vue";
import PageLayout from "@/app/page-layout.vue";
import ConnectAdChannelDialog from "@/app/workspace/ad-channels/connect-ad-channel-dialog.vue";
import ConnectMoreOrContinueDialog from "@/app/workspace/ad-channels/connect-more-or-continue-dialog.vue";
import GoogleAdAccountOptionsDialog from "@/app/workspace/ad-channels/google/google-ad-account-options-dialog.vue";
import MetaAdAccountOptionsDialog from "@/app/workspace/ad-channels/meta/meta-ad-account-options-dialog.vue";
import DialogWrapper from "@/components/dialog-wrapper.vue";
import { GoogleAdAccountOption } from "@/dtos/google-ad-account-option.ts";
import { MetaAdAccountOption } from "@/dtos/meta-ad-account-option.ts";
import { adChannelTypeDisplayName } from "@/enums/ad-channel-type.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();
const route = useRoute();
const router = useRouter();

const connect_dialog_open = ref<boolean>(false);
const connect_more_or_continue_dialog_open = ref<boolean>(false);

const meta_options_dialog_open = ref<boolean>(false);
const meta_access_token = ref<string | undefined>(undefined);
const meta_ad_channels = ref<MetaAdAccountOption[] | undefined>(undefined);

const google_options_dialog_open = ref<boolean>(false);
const google_access_token = ref<string | undefined>(undefined);
const google_refresh_token = ref<string | undefined>(undefined);
const google_ad_channels = ref<GoogleAdAccountOption[] | undefined>(undefined);

const loaded = () => {
  if (
    route.query.option === "meta" &&
    route.query.access_token &&
    route.query.ad_channels
  ) {
    meta_access_token.value = route.query.access_token as string;
    meta_ad_channels.value = JSON.parse(
      route.query.ad_channels as string,
    ) as MetaAdAccountOption[];

    meta_options_dialog_open.value = true;
  } else if (
    route.query.option === "google" &&
    route.query.access_token &&
    route.query.refresh_token &&
    route.query.ad_channels
  ) {
    google_access_token.value = route.query.access_token as string;
    google_refresh_token.value = route.query.refresh_token as string;
    google_ad_channels.value = JSON.parse(
      route.query.ad_channels as string,
    ) as GoogleAdAccountOption[];

    google_options_dialog_open.value = true;
  } else if (route.query.setup && route.query.done) {
    connect_more_or_continue_dialog_open.value = true;
  } else if (route.query.setup) {
    connect_dialog_open.value = true;
  }
};

if (workspaceStore.workspace) {
  loaded();
} else {
  watchOnce(() => workspaceStore.workspace, loaded);
}

const has_ad_channels = computed(() => {
  return (
    workspaceStore.workspace && workspaceStore.workspace.ad_channels.length >= 1
  );
});

const proceedWithSetup = () => {
  router.push({
    name: "campaigns",
    params: {
      workspace: workspaceStore.workspace!.id,
    },
    query: {
      setup: "true",
    },
  });
};
</script>
<template>
  <page-layout
    v-if="workspaceStore.workspace"
    :tabs="has_ad_channels"
    :centered="!has_ad_channels"
  >
    <template v-if="!has_ad_channels">
      <button class="button large -mt-32" @click="connect_dialog_open = true">
        Connect ad channels
      </button>
    </template>
    <template v-else>
      <div class="mx-9">
        <table class="table table-auto">
          <thead class="text-left">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <!--              <th>Last synced</th>-->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="ad_channel in workspaceStore.workspace.ad_channels"
              :key="ad_channel.id"
            >
              <td>{{ ad_channel.name }}</td>
              <td>
                <div class="flex items-center space-x-4">
                  <ad-channel-icon
                    :type="ad_channel.type"
                    class="h-5 w-auto"
                  ></ad-channel-icon>
                  <span>{{ adChannelTypeDisplayName(ad_channel.type) }}</span>
                </div>
              </td>
              <!--              <td></td>-->
            </tr>
          </tbody>
        </table>
        <button class="button mt-20" @click="connect_dialog_open = true">
          Connect ad channel
        </button>
      </div>
    </template>
    <dialog-wrapper v-model="connect_dialog_open">
      <connect-ad-channel-dialog
        @cancel="connect_dialog_open = false"
      ></connect-ad-channel-dialog>
    </dialog-wrapper>
    <dialog-wrapper v-model="connect_more_or_continue_dialog_open">
      <connect-more-or-continue-dialog
        @cancel="connect_more_or_continue_dialog_open = false"
        @add-more="
          () => {
            connect_dialog_open = true;
            connect_more_or_continue_dialog_open = false;
          }
        "
        @continue="proceedWithSetup"
      ></connect-more-or-continue-dialog>
    </dialog-wrapper>
    <dialog-wrapper v-model="meta_options_dialog_open">
      <meta-ad-account-options-dialog
        v-if="meta_access_token && meta_ad_channels"
        :access-token="meta_access_token"
        :ad-channels="meta_ad_channels"
        @cancel="meta_options_dialog_open = false"
        @complete="
          () => {
            meta_options_dialog_open = false;
            if (route.query.setup) {
              connect_more_or_continue_dialog_open = true;
            }
          }
        "
      ></meta-ad-account-options-dialog>
    </dialog-wrapper>
    <dialog-wrapper v-model="google_options_dialog_open">
      <google-ad-account-options-dialog
        v-if="google_access_token && google_refresh_token && google_ad_channels"
        :access-token="google_access_token"
        :refresh-token="google_refresh_token"
        :ad-channels="google_ad_channels"
        @cancel="google_options_dialog_open = false"
        @complete="
          () => {
            google_options_dialog_open = false;
            if (route.query.setup) {
              connect_more_or_continue_dialog_open = true;
            }
          }
        "
      ></google-ad-account-options-dialog>
    </dialog-wrapper>
  </page-layout>
</template>
