<script setup lang="ts">
import { SwitchRoot, SwitchThumb } from "radix-vue";
import { ref, watch } from "vue";

import Tooltip from "@/components/tooltip.vue";

const emit = defineEmits<{
  (e: "change", state: boolean): void;
}>();
const switchState = defineModel<boolean>();
const forcedOffState = ref<boolean>(false);

const props = defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  forceOff: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabledTooltip: {
    type: String,
    required: false,
    default: undefined,
  },
  labelFirst: {
    type: Boolean,
    required: false,
    default: false,
  },
});

watch(switchState, () => {
  emit("change", switchState.value || false);
});
</script>

<template>
  <tooltip :disabled="!props.disabled">
    <template #content>
      <span class="inline-block max-w-[220px]">{{
        props.disabledTooltip
      }}</span>
    </template>
    <template #trigger>
      <div
        :class="`flex items-center space-x-3 ${props.disabled ? `cursor-not-allowed` : ``} ${props.labelFirst ? `flex-row-reverse space-x-reverse` : ``}`"
      >
        <SwitchRoot
          v-if="!props.forceOff"
          v-model:checked="switchState"
          :disabled="props.disabled"
          :class="`relative flex h-[23px] w-[40px] ${props.disabled ? `cursor-not-allowed` : `cursor-pointer`} items-center rounded-full bg-zinc-200 shadow-sm outline-none data-[state=checked]:bg-black dark:bg-zinc-900 dark:data-[state=checked]:bg-white`"
        >
          <SwitchThumb
            class="my-auto block h-[19px] w-[18px] translate-x-0.5 rounded-full bg-white shadow-sm transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[20px] dark:bg-black"
          />
        </SwitchRoot>
        <SwitchRoot
          v-else
          v-model:checked="forcedOffState"
          :disabled="props.disabled"
          :class="`relative flex h-[23px] w-[40px] ${props.disabled ? `cursor-not-allowed` : `cursor-pointer`} items-center rounded-full bg-zinc-200 shadow-sm outline-none data-[state=checked]:bg-black dark:bg-zinc-900 dark:data-[state=checked]:bg-white`"
        >
          <SwitchThumb
            class="my-auto block h-[19px] w-[18px] translate-x-0.5 rounded-full bg-white shadow-sm transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[20px] dark:bg-black"
          />
        </SwitchRoot>
        <div
          :class="`select-none ${props.disabled ? `cursor-not-allowed` : `cursor-pointer`}`"
          @click="
            () => {
              if (!props.disabled) {
                switchState = !switchState;
              }
            }
          "
        >
          <slot></slot>
        </div>
      </div>
    </template>
  </tooltip>
</template>
