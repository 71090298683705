export const formatNumber = (
  value: number,
  dp: number,
  decimal_point?: string,
  thousand_separator?: string,
): string => {
  const multiplier = Math.pow(10, dp); // Create a multiplier based on decimal places
  return (Math.round(value * multiplier) / multiplier)
    .toFixed(dp)
    .replace(".", decimal_point ?? ".")
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, thousand_separator ?? ",");
};
