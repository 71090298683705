<script setup lang="ts">
import { Cross2Icon } from "@radix-icons/vue";
import { PropType } from "vue";

const emit = defineEmits<{
  (e: "close"): void;
}>();
const props = defineProps({
  title: {
    type: String as PropType<string>,
    required: false,
    default: undefined,
  },
  titleBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  component: {
    type: Object,
    required: false,
    default: undefined,
  },
});
</script>
<template>
  <div
    v-if="props.title"
    :class="`flex items-center justify-between border-0 border-white/10 pl-6 text-lg font-semibold ${props.titleBorder ? `h-[60px] border-b-[1px]` : `h-[61px]`}`"
  >
    <span class="relative -top-[1px]">{{ props.title }}</span>
    <div class="flex items-center">
      <div class="relative -top-[1px]">
        <slot name="right"></slot>
      </div>
      <div class="group cursor-pointer px-5 py-4" @click="emit('close')">
        <Cross2Icon
          class="h-6 w-6 opacity-30 transition-all duration-75 group-hover:opacity-100"
        ></Cross2Icon>
      </div>
    </div>
  </div>
</template>
