import { RouteLocation, RouteLocationRaw } from "vue-router";

export type Item = {
  title: string;
  route?: RouteLocationRaw;
  items?: Item[];
};

export const contents: Item[] = [
  {
    title: "Introduction",
    route: {
      name: "docs-introduction",
    },
  },
  {
    title: "Core concepts",
    items: [
      {
        title: "Shared budgets",
        route: {
          name: "docs-shared-budgets",
        },
      },
      {
        title: "Target metrics",
        route: {
          name: "docs-target-metrics",
        },
      },
      {
        title: "Campaign thresholds",
        route: {
          name: "docs-campaign-thresholds",
        },
      },
      {
        title: "Budget change approval",
        route: {
          name: "docs-budget-change-approval",
        },
      },
    ],
  },
  {
    title: "Onboarding",
    items: [
      {
        title: "Creating an account",
        route: {
          name: "docs-creating-an-account",
        },
      },
      {
        title: "Connecting ad channels",
        route: {
          name: "docs-connecting-ad-channels",
        },
      },
      {
        title: "Creating shared budgets",
        route: {
          name: "docs-creating-shared-budgets",
        },
      },
      {
        title: "Assigning campaigns",
        route: {
          name: "docs-assigning-campaigns",
        },
      },
      {
        title: "Campaign dashboard",
        route: {
          name: "docs-campaign-dashboard",
        },
      },
    ],
  },
  {
    title: "Account management",
    items: [
      {
        title: "Workspaces",
        route: {
          name: "docs-workspaces",
        },
      },
      {
        title: "Team access",
        route: {
          name: "docs-team-access",
        },
      },
      {
        title: "Billing",
        route: {
          name: "docs-billing",
        },
      },
    ],
  },
];

function flattenItems(items: Item[]): Item[] {
  return items.reduce<Item[]>((acc, item) => {
    acc.push(item);
    if (item.items) {
      acc.push(...flattenItems(item.items));
    }
    return acc;
  }, []);
}

export const selectedItem = (
  route: RouteLocation,
  contents: Item[],
): Item | undefined => {
  for (let i = 0; i < contents.length; i++) {
    if (
      contents[i].route &&
      (contents[i].route as RouteLocation).name === route.name
    ) {
      return contents[i];
    }

    if (contents[i].items?.length) {
      const sub_item = selectedItem(route, contents[i].items!);
      if (sub_item) {
        return sub_item;
      }
    }
  }
  return undefined;
};

export const nextItem = (
  item: Item,
  contents: Item[],
  pre_flattened: boolean = false,
) => {
  const flattened_items = pre_flattened ? contents : flattenItems(contents);

  for (let i = 0; i < flattened_items.length; i++) {
    if (item.title === flattened_items[i].title) {
      let ii = i + 1;
      while (flattened_items.length > ii) {
        if (flattened_items[ii].route) {
          return flattened_items[ii];
        }
        ii++;
      }
      return undefined;
    }
  }
  return undefined;
};

export const prevItem = (
  item: Item,
  contents: Item[],
  pre_flattened: boolean = false,
) => {
  const flattened_items = pre_flattened
    ? contents
    : flattenItems(contents).reverse();
  return nextItem(item, flattened_items, true);
};

export const itemAndDescendants = (item: Item): Item[] => {
  const items: Item[] = [];
  items.push(item);
  if (item.items) {
    item.items.forEach((subitem) => {
      items.push(...itemAndDescendants(subitem));
    });
  }
  return items;
};

export const isSelected = (
  route: RouteLocation,
  item: Item,
  include_descendants: boolean = false,
) => {
  if (include_descendants) {
    const item_and_descendants = itemAndDescendants(item);
    for (let i = 0; i < item_and_descendants.length; i++) {
      if (
        item_and_descendants[i].route &&
        (item_and_descendants[i].route as RouteLocation).name === route.name
      ) {
        return true;
      }
    }
  } else {
    if (item.route && (item.route as RouteLocation).name === route.name) {
      return true;
    }
  }
  return false;
};
