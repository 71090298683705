<script setup lang="ts">
import { watchOnce } from "@vueuse/core";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import AppHeader from "@/app/workspace/app-header.vue";
import CreateBudgetDialog from "@/app/workspace/campaigns/shared-budgets/create-shared-budget-dialog.vue";
import MobileMenu from "@/app/workspace/mobile-menu.vue";
import Switcher from "@/app/workspace/switcher.vue";
import UserMenu from "@/app/workspace/user-menu.vue";
import DialogWrapper from "@/components/dialog-wrapper.vue";
import StyledButton from "@/components/form/styled-button.vue";
import LoadingBar from "@/components/loading-bar.vue";
import { ButtonType } from "@/enums/button-type.ts";
import { api } from "@/helpers/api.ts";
import { useAuthStore } from "@/stores/auth.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();
// const visibility = useDocumentVisibility();

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();

const simulation_active = ref<boolean>(false);

// const interval: number | undefined = undefined;

// const startLiving = () => {
//   if (interval !== undefined) {
//     clearInterval(interval);
//   }
//   interval = window.setInterval(() => {
//     if (workspaceStore.workspace) {
//       workspaceStore.reload(workspaceStore.workspace);
//     }
//   }, 60000); // 1 min
// };
// const stopLiving = () => {
//   clearInterval(interval);
//   interval = undefined;
// };
//
// onMounted(() => {
//   startLiving();
// });
// onBeforeUnmount(() => {
//   stopLiving();
// });

// watch(visibility, (current, previous) => {
//   if (current === "visible" && previous === "hidden") {
//     startLiving();
//     if (workspaceStore.workspace) {
//       workspaceStore.reload(workspaceStore.workspace);
//     }
//   }
//   if (current === "hidden" && previous === "visible") {
//     stopLiving();
//   }
// });

const loaded = () => {
  if (!authStore.user!.terms_agreed_at) {
    router.replace({
      name: "terms",
      query: {
        for: btoa(JSON.stringify(route.fullPath)),
      },
    });
    return;
  }
  if (route.name === "campaigns" && route.query.setup === "true") {
    create_budget_dialog_open.value = true;
  }
};

const create_budget_dialog_open = ref<boolean>(false);

onMounted(() => {
  if (workspaceStore.workspaces !== undefined) {
    loaded();
  } else {
    watchOnce(() => workspaceStore.workspaces, loaded);
  }
});

const startSimulation = () => {
  simulation_active.value = true;
  runSimulation();
};
const stopSimulation = () => {
  simulation_active.value = false;
};

const runSimulation = () => {
  if (simulation_active.value && workspaceStore.workspace) {
    api.get(`workspace/simulate?workspace_id=${workspaceStore.workspace.id}`);
    setTimeout(runSimulation, 5000);
  }
};
</script>
<template>
  <div class="flex grow flex-col">
    <div v-if="workspaceStore.workspace" class="flex grow flex-col">
      <app-header>
        <template #left
          ><switcher class="relative top-[3px] hidden lg:flex"></switcher
        ></template>
        <template #right
          ><div class="hidden items-center space-x-6 lg:flex">
            <div
              class="flex items-center space-x-8"
              v-if="
                workspaceStore.workspace &&
                (['campaigns'] as string[]).includes(route.name as string)
              "
            >
              <styled-button
                v-if="workspaceStore.workspace.simulated"
                @click="
                  () => {
                    if (simulation_active) {
                      stopSimulation();
                    } else {
                      startSimulation();
                    }
                  }
                "
                :type="
                  simulation_active
                    ? ButtonType.DESTRUCTIVE
                    : ButtonType.REGULAR
                "
              >
                {{
                  simulation_active ? `Pause simulation` : `Start simulation`
                }}
              </styled-button>
              <styled-button
                @click="create_budget_dialog_open = true"
                :type="
                  workspaceStore.workspace.shared_budgets.length === 0
                    ? ButtonType.PRIMARY
                    : ButtonType.REGULAR
                "
              >
                Create shared budget
              </styled-button>
            </div>
            <user-menu />
          </div>
          <mobile-menu class="lg:hidden"></mobile-menu
        ></template>
      </app-header>
      <router-view></router-view>
    </div>
    <div class="flex grow items-center justify-center" v-else>
      <!--      <spinner class="h-9 w-9" />-->

      <loading-bar></loading-bar>
    </div>
    <dialog-wrapper v-model="create_budget_dialog_open">
      <create-budget-dialog
        v-if="workspaceStore.workspace"
        title="Create shared budget"
        @close="create_budget_dialog_open = false"
      ></create-budget-dialog>
    </dialog-wrapper>
  </div>
</template>
