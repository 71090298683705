<script setup lang="ts">
import { PropType } from "vue";

import { Campaign } from "@/dtos/campaign.ts";
import { useCurrencyStore } from "@/stores/currency.ts";

const currencyStore = useCurrencyStore();

const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
});
</script>
<template>
  <div class="text-sm text-zinc-400 dark:text-purple-300/70">
    <template v-if="props.campaign.effective_daily_budget">
      <span>{{
        currencyStore.format(
          props.campaign.effective_daily_budget * (365.25 / 12),
          props.campaign.currency,
        )
      }}</span>
      monthly budget
    </template>
    <template
      v-else-if="
        props.campaign.lifetime_budget && props.campaign.budget_remaining
      "
    >
      <span
        >{{
          currencyStore.format(
            props.campaign.budget_remaining,
            props.campaign.currency,
          )
        }}
        /
        {{
          currencyStore.format(
            props.campaign.lifetime_budget,
            props.campaign.currency,
          )
        }}</span
      >
      budget remaining
    </template>
  </div>
</template>
