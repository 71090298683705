<script setup lang="ts">
import { computed, PropType, ref } from "vue";

import AdChannelIcon from "@/app/ad-channel-icon.vue";
import CampaignMenu from "@/app/workspace/campaigns/blocks/campaign-menu.vue";
import EffectiveDailyBudget from "@/app/workspace/campaigns/blocks/effective-daily-budget.vue";
import Graph, {
  DataPoint,
  DataSeries,
  PotentialDataPoint,
} from "@/app/workspace/campaigns/blocks/graph.vue";
import MetricForecast from "@/app/workspace/campaigns/blocks/metric-forecast.vue";
import Popout from "@/app/workspace/campaigns/metrics/popout.vue";
import { Campaign } from "@/dtos/campaign.ts";
import { CampaignMetrics } from "@/dtos/campaign-metrics.ts";
import { SharedBudget } from "@/dtos/shared-budget.ts";
import { TargetMetric } from "@/enums/target-metric.ts";
import { formatNumber } from "@/helpers/formatting.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useInternationalisationStore } from "@/stores/internationalisation.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
  group: {
    type: Object as PropType<SharedBudget>,
    required: false,
    default: undefined,
  },
  uuid: {
    type: String,
    required: false,
    default: undefined,
  },
});
const currencyStore = useCurrencyStore();
const workspaceStore = useWorkspaceStore();
const internationalisationStore = useInternationalisationStore();

// const all_ad_channels_have_roas = computed<boolean>(() => {
//   let value = true;
//   if (props.campaign.weekly_roas_in_last_window === undefined) {
//     value = false;
//   }
//   return value;
// });
// const any_ad_channels_active = computed<boolean>(() => {
//   let value = false;
//   if (props.campaign.active) {
//     value = true;
//   }
//   return value;
// });

const data = computed(() => {
  const series: DataSeries[] = [];

  const budget_line_color = "rgb(121,99,149)";
  const budget_text_color = "rgba(216, 180, 254, 1)";
  const positive_metric_line_color = "#0faa77";
  const positive_metric_text_color = "#00ffac";
  const negative_metric_line_color = "#ff4a7a";
  const negative_metric_text_color = "#ff6991";
  const neutral_metric_line_color = "rgba(255,255,255,0.35)";
  const neutral_metric_text_color = "#ffffff";

  const merged_time_series = workspaceStore
    .workspace!.campaigns.map((campaign: Campaign) => campaign.time_series)
    .flat();

  const merged_effective_budgets = workspaceStore
    .workspace!.campaigns.map(
      (campaign: Campaign) => campaign.effective_daily_budget,
    )
    .flat();

  // add budget

  const max_budget_y_axis = Math.ceil(
    Math.max(
      ...merged_time_series
        .filter((item: CampaignMetrics) => item.spend_yesterday !== undefined)
        .map((item: CampaignMetrics) => item.spend_yesterday!),
      ...merged_effective_budgets,
    ),
  );

  const budget_values = props.campaign.time_series
    .map((item) => {
      const item_time = item.time;
      let date = new Date(item_time);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      date = new Date(date.getTime() - 43200000);
      return {
        date: date,
        value: item.spend_yesterday ?? undefined,
      } as PotentialDataPoint;
    })
    .filter(
      (item: PotentialDataPoint) => item.value !== undefined,
    ) as DataPoint[];

  budget_values.push({
    date: new Date(new Date().setHours(23, 59, 59, 999)),
    value: props.campaign.effective_daily_budget, // todo: adjust for proportion of the forecast day until now?
    label_override: "Daily budget",
  } as DataPoint);

  series.push({
    label: "Spend",
    positive_line_color: budget_line_color,
    positive_text_color: budget_text_color,
    muted: false,
    y_axis_from: 0,
    y_axis_to: max_budget_y_axis,
    values: budget_values,
    valueFormatter: (value: number) =>
      currencyStore.format(value, props.campaign.currency),
  });

  // add other data series
  if (props.group?.target_metric === TargetMetric.REVENUE) {
    const max_revenue_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter(
            (item: CampaignMetrics) => item.revenue_yesterday !== undefined,
          )
          .map((item: CampaignMetrics) => item.revenue_yesterday!),
      ),
    );
    series.push({
      label: "Revenue",
      positive_line_color: positive_metric_line_color,
      positive_text_color: positive_metric_text_color,
      negative_line_color: negative_metric_line_color,
      negative_text_color: negative_metric_text_color,
      isPositive: (value: number) => value > 0,
      muted:
        !!props.group && props.group?.target_metric !== TargetMetric.REVENUE,
      y_axis_from: 0,
      y_axis_to: max_revenue_y_axis,
      values: props.campaign.time_series
        .map((item) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.revenue_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) =>
        currencyStore.format(value, props.campaign.currency),
    });
  }

  if (
    !props.group ||
    props.group?.target_metric === TargetMetric.ROAS ||
    props.group?.target_metric === TargetMetric.AD_PROFIT
  ) {
    const max_ad_profit_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter(
            (item: CampaignMetrics) => item.ad_profit_yesterday !== undefined,
          )
          .map((item: CampaignMetrics) => item.ad_profit_yesterday!),
      ),
    );
    series.push({
      label: "Ad profit",
      positive_line_color: positive_metric_line_color,
      positive_text_color: positive_metric_text_color,
      negative_line_color: negative_metric_line_color,
      negative_text_color: negative_metric_text_color,
      isPositive: (value: number) => value > 0,
      muted:
        !!props.group && props.group?.target_metric !== TargetMetric.AD_PROFIT,
      y_axis_from: 0,
      y_axis_to: max_ad_profit_y_axis,
      values: props.campaign.time_series
        .map((item) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.ad_profit_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) =>
        currencyStore.format(value, props.campaign.currency),
    });
  }

  if (props.group?.target_metric === TargetMetric.REACH) {
    const max_reach_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter((item: CampaignMetrics) => item.reach_yesterday !== undefined)
          .map((item: CampaignMetrics) => item.reach_yesterday!),
      ),
    );
    series.push({
      label: "Reach",
      positive_line_color: !props.group
        ? neutral_metric_line_color
        : positive_metric_line_color,
      positive_text_color: !props.group
        ? neutral_metric_text_color
        : positive_metric_text_color,
      negative_line_color: !props.group
        ? neutral_metric_line_color
        : negative_metric_line_color,
      negative_text_color: !props.group
        ? neutral_metric_text_color
        : negative_metric_text_color,
      isPositive: (value: number) => value > 0,
      muted: props.group?.target_metric !== TargetMetric.REACH,
      y_axis_from: 0,
      y_axis_to: max_reach_y_axis,
      values: props.campaign.time_series
        .map((item) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.reach_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) => formatNumber(value, 0),
    });
  }
  if (props.group?.target_metric === TargetMetric.IMPRESSIONS) {
    const max_impressions_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter(
            (item: CampaignMetrics) => item.impressions_yesterday !== undefined,
          )
          .map((item: CampaignMetrics) => item.impressions_yesterday!),
      ),
    );
    series.push({
      label: "Impressions",
      positive_line_color: !props.group
        ? neutral_metric_line_color
        : positive_metric_line_color,
      positive_text_color: !props.group
        ? neutral_metric_text_color
        : positive_metric_text_color,
      negative_line_color: !props.group
        ? neutral_metric_line_color
        : negative_metric_line_color,
      negative_text_color: !props.group
        ? neutral_metric_text_color
        : negative_metric_text_color,
      isPositive: (value: number) => value > 0,
      muted: props.group?.target_metric !== TargetMetric.IMPRESSIONS,
      y_axis_from: 0,
      y_axis_to: max_impressions_y_axis,
      values: props.campaign.time_series
        .map((item) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.impressions_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) => formatNumber(value, 0),
    });
  }
  if (props.group?.target_metric === TargetMetric.CLICKS) {
    const max_clicks_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter(
            (item: CampaignMetrics) => item.clicks_yesterday !== undefined,
          )
          .map((item: CampaignMetrics) => item.clicks_yesterday!),
      ),
    );
    series.push({
      label: "Clicks",
      positive_line_color: !props.group
        ? neutral_metric_line_color
        : positive_metric_line_color,
      positive_text_color: !props.group
        ? neutral_metric_text_color
        : positive_metric_text_color,
      negative_line_color: !props.group
        ? neutral_metric_line_color
        : negative_metric_line_color,
      negative_text_color: !props.group
        ? neutral_metric_text_color
        : negative_metric_text_color,
      isPositive: (value: number) => value > 0,
      muted: props.group?.target_metric !== TargetMetric.CLICKS,
      y_axis_from: 0,
      y_axis_to: max_clicks_y_axis,
      values: props.campaign.time_series
        .map((item) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.clicks_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) => formatNumber(value, 0),
    });
  }
  if (props.group?.target_metric === TargetMetric.UNIQUE_CLICKS) {
    const max_unique_clicks_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter(
            (item: CampaignMetrics) =>
              item.unique_clicks_yesterday !== undefined,
          )
          .map((item: CampaignMetrics) => item.unique_clicks_yesterday!),
      ),
    );
    series.push({
      label: "Unique clicks",
      positive_line_color: !props.group
        ? neutral_metric_line_color
        : positive_metric_line_color,
      positive_text_color: !props.group
        ? neutral_metric_text_color
        : positive_metric_text_color,
      negative_line_color: !props.group
        ? neutral_metric_line_color
        : negative_metric_line_color,
      negative_text_color: !props.group
        ? neutral_metric_text_color
        : negative_metric_text_color,
      isPositive: (value: number) => value > 0,
      muted: props.group?.target_metric !== TargetMetric.UNIQUE_CLICKS,
      y_axis_from: 0,
      y_axis_to: max_unique_clicks_y_axis,
      values: props.campaign.time_series
        .map((item) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.unique_clicks_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) => formatNumber(value, 0),
    });
  }
  if (
    !props.group ||
    props.group?.target_metric === TargetMetric.ROAS ||
    props.group?.target_metric === TargetMetric.AD_PROFIT ||
    props.group?.target_metric === TargetMetric.REVENUE ||
    props.group?.target_metric === TargetMetric.COST_PER_PURCHASE
  ) {
    const max_roas = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter(
            (item: CampaignMetrics) => item.roas_in_previous_week !== undefined,
          )
          .map((item: CampaignMetrics) => item.roas_in_previous_week!),
      ),
    );

    series.push({
      label: "Return on ad spend",
      positive_line_color: positive_metric_line_color,
      positive_text_color: positive_metric_text_color,
      negative_line_color: negative_metric_line_color,
      negative_text_color: negative_metric_text_color,
      isPositive: (value: number) => value >= 1,
      muted: !props.group || props.group?.target_metric !== TargetMetric.ROAS,
      y_axis_from: 0,
      y_axis_to: max_roas,
      values: props.campaign.time_series
        .map((item) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.roas_in_previous_week ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) => formatNumber(value, 2),
    });
  }
  if (
    props.group?.target_metric === TargetMetric.AVERAGE_CONVERSION_REVENUE ||
    props.group?.target_metric === TargetMetric.COST_PER_PURCHASE
  ) {
    const max_cpp_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter((item: CampaignMetrics) => item.cpp_yesterday !== undefined)
          .map((item: CampaignMetrics) => item.cpp_yesterday!),
      ),
    );

    // todo: use the group thresholds for isPositive
    series.push({
      label: "Cost per purchase",
      positive_line_color: !props.group
        ? neutral_metric_line_color
        : positive_metric_line_color,
      positive_text_color: !props.group
        ? neutral_metric_text_color
        : positive_metric_text_color,
      negative_line_color: !props.group
        ? neutral_metric_line_color
        : negative_metric_line_color,
      negative_text_color: !props.group
        ? neutral_metric_text_color
        : negative_metric_text_color,
      isPositive: () => true,
      muted: props.group?.target_metric !== TargetMetric.COST_PER_PURCHASE,
      y_axis_from: 0,
      y_axis_to: max_cpp_y_axis,
      values: props.campaign.time_series
        .map((item: CampaignMetrics) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.cpp_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) =>
        currencyStore.format(value, props.campaign.currency),
    });
  }
  if (props.group?.target_metric === TargetMetric.AVERAGE_CONVERSION_REVENUE) {
    const max_acr_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter(
            (item: CampaignMetrics) =>
              item.avg_conversion_revenue_yesterday !== undefined,
          )
          .map(
            (item: CampaignMetrics) => item.avg_conversion_revenue_yesterday!,
          ),
      ),
    );
    series.push({
      label: "Average purchase revenue",
      positive_line_color: !props.group
        ? neutral_metric_line_color
        : positive_metric_line_color,
      positive_text_color: !props.group
        ? neutral_metric_text_color
        : positive_metric_text_color,
      negative_line_color: !props.group
        ? neutral_metric_line_color
        : negative_metric_line_color,
      negative_text_color: !props.group
        ? neutral_metric_text_color
        : negative_metric_text_color,
      isPositive: () => true,
      muted:
        props.group?.target_metric !== TargetMetric.AVERAGE_CONVERSION_REVENUE,
      y_axis_from: 0,
      y_axis_to: max_acr_y_axis,
      values: props.campaign.time_series
        .map((item: CampaignMetrics) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.avg_conversion_revenue_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) =>
        currencyStore.format(value, props.campaign.currency),
    });
  }
  if (props.group?.target_metric === TargetMetric.REACH) {
    const max_ucpm_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter(
            (item: CampaignMetrics) => item.unique_cpm_yesterday !== undefined,
          )
          .map((item: CampaignMetrics) => item.unique_cpm_yesterday!),
      ),
    );
    series.push({
      label: "Cost per unique mille",
      positive_line_color: !props.group
        ? neutral_metric_line_color
        : positive_metric_line_color,
      positive_text_color: !props.group
        ? neutral_metric_text_color
        : positive_metric_text_color,
      negative_line_color: !props.group
        ? neutral_metric_line_color
        : negative_metric_line_color,
      negative_text_color: !props.group
        ? neutral_metric_text_color
        : negative_metric_text_color,
      isPositive: () => true,
      muted: true,
      y_axis_from: 0,
      y_axis_to: max_ucpm_y_axis,
      // props.group?.target_metric !== TargetMetric.COST_PER_UNIQUE_MILLE,
      values: props.campaign.time_series
        .map((item: CampaignMetrics) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.unique_cpm_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) =>
        currencyStore.format(value, props.campaign.currency),
    });
  }
  if (props.group?.target_metric === TargetMetric.IMPRESSIONS) {
    const max_cpm_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter((item: CampaignMetrics) => item.cpm_yesterday !== undefined)
          .map((item: CampaignMetrics) => item.cpm_yesterday!),
      ),
    );
    series.push({
      label: "Cost per mille",
      positive_line_color: positive_metric_line_color,
      positive_text_color: positive_metric_text_color,
      negative_line_color: negative_metric_line_color,
      negative_text_color: negative_metric_text_color,
      isPositive: () => true,
      muted: true,
      // props.group?.target_metric !== TargetMetric.COST_PER_MILLE,
      y_axis_from: 0,
      y_axis_to: max_cpm_y_axis,
      values: props.campaign.time_series
        .map((item: CampaignMetrics) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.cpm_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) =>
        currencyStore.format(value, props.campaign.currency),
    });
  }
  if (props.group?.target_metric === TargetMetric.CLICKS) {
    const max_cpc_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter((item: CampaignMetrics) => item.cpc_yesterday !== undefined)
          .map((item: CampaignMetrics) => item.cpc_yesterday!),
      ),
    );
    series.push({
      label: "Cost per click",
      positive_line_color: positive_metric_line_color,
      positive_text_color: positive_metric_text_color,
      negative_line_color: negative_metric_line_color,
      negative_text_color: negative_metric_text_color,
      isPositive: () => true,
      muted: true,
      // props.group?.target_metric !== TargetMetric.COST_PER_CLICK,
      y_axis_from: 0,
      y_axis_to: max_cpc_y_axis,
      values: props.campaign.time_series
        .map((item: CampaignMetrics) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.cpc_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) =>
        currencyStore.format(value, props.campaign.currency),
    });
  }
  if (props.group?.target_metric === TargetMetric.UNIQUE_CLICKS) {
    const max_ucpc_y_axis = Math.ceil(
      Math.max(
        ...merged_time_series
          .filter(
            (item: CampaignMetrics) => item.unique_cpc_yesterday !== undefined,
          )
          .map((item: CampaignMetrics) => item.unique_cpc_yesterday!),
      ),
    );
    series.push({
      label: "Cost per unique click",
      positive_line_color: !props.group
        ? neutral_metric_line_color
        : positive_metric_line_color,
      positive_text_color: !props.group
        ? neutral_metric_text_color
        : positive_metric_text_color,
      negative_line_color: !props.group
        ? neutral_metric_line_color
        : negative_metric_line_color,
      negative_text_color: !props.group
        ? neutral_metric_text_color
        : negative_metric_text_color,
      isPositive: () => true,
      muted: true,
      // props.group?.target_metric !== TargetMetric.COST_PER_UNIQUE_CLICK,
      y_axis_from: 0,
      y_axis_to: max_ucpc_y_axis,
      values: props.campaign.time_series
        .map((item: CampaignMetrics) => {
          const item_time = item.time;
          let date = new Date(item_time);
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          date = new Date(date.getTime() - 43200000);
          return {
            date: date,
            value: item.unique_cpc_yesterday ?? undefined,
          } as PotentialDataPoint;
        })
        .filter(
          (item: PotentialDataPoint) => item.value !== undefined,
        ) as DataPoint[],
      valueFormatter: (value: number) =>
        currencyStore.format(value, props.campaign.currency),
    });
  }

  // filter down data series based on what data we actually have available
  return series.filter((series_: DataSeries) => series_.values.length >= 1);
});

const metrics_open = ref<boolean>(false);
const openMetrics = () => {
  metrics_open.value = true;
};
const closeMetrics = () => {
  metrics_open.value = false;
};

const ready = computed<boolean>(() => {
  if (!props.group) {
    return true;
  }
  if (
    props.group.target_metric === TargetMetric.AD_PROFIT &&
    props.campaign.daily_ad_profit_forecast !== undefined
  ) {
    return true;
  }
  if (
    props.group.target_metric === TargetMetric.REVENUE &&
    props.campaign.daily_revenue_forecast !== undefined
  ) {
    return true;
  }
  if (
    props.group.target_metric === TargetMetric.REACH &&
    props.campaign.daily_reach_forecast !== undefined
  ) {
    return true;
  }
  if (
    props.group.target_metric === TargetMetric.IMPRESSIONS &&
    props.campaign.daily_impressions_forecast !== undefined
  ) {
    return true;
  }
  if (
    props.group.target_metric === TargetMetric.CLICKS &&
    props.campaign.daily_clicks_forecast !== undefined
  ) {
    return true;
  }
  if (
    props.group.target_metric === TargetMetric.UNIQUE_CLICKS &&
    props.campaign.daily_unique_clicks_forecast !== undefined
  ) {
    return true;
  }
  if (
    props.group.target_metric === TargetMetric.ROAS &&
    props.campaign.roas_in_previous_week !== undefined
  ) {
    return true;
  }
  if (
    props.group.target_metric === TargetMetric.AVERAGE_CONVERSION_REVENUE &&
    props.campaign.daily_acr_forecast !== undefined
  ) {
    return true;
  }
  if (
    props.group.target_metric === TargetMetric.COST_PER_PURCHASE &&
    props.campaign?.daily_cpp_forecast !== undefined
  ) {
    return true;
  }
  return false;
});
</script>
<template>
  <popout
    v-if="metrics_open"
    :campaign="props.campaign"
    @close="closeMetrics"
  ></popout>
  <div
    :class="`campaign card font-mono ${props.group || true ? `aspect-[7/5]` : ``} ${ready ? `` : `opacity-75`}`"
    v-if="internationalisationStore.country"
  >
    <div :class="`flex h-14 items-center justify-between px-4 sm:px-5`">
      <div
        class="z-10 flex items-center space-x-3 text-base font-semibold leading-5 sm:text-base sm:leading-none"
      >
        <ad-channel-icon
          class="h-5 w-5"
          :type="props.campaign.ad_channel.type"
        ></ad-channel-icon>
        <span>{{ props.campaign.name }}</span>
      </div>

      <div class="flex flex-none items-center space-x-0.5 sm:space-x-1.5">
        <div class="flex items-center">
          <!--          <div-->
          <!--            @click="openMetrics"-->
          <!--            class="mr-1.5 transform cursor-pointer fill-current p-2 pb-3.5 opacity-20 transition-all duration-300 hover:opacity-100"-->
          <!--          >-->
          <!--            <BarChartIcon class="h-6 w-6"></BarChartIcon>-->
          <!--          </div>-->
          <div class="text-sm">
            <!--            <toggle-->
            <!--              v-model="managed"-->
            <!--              :disabled="!props.campaign?.shared_budget_id"-->
            <!--              :force-off="!props.campaign?.shared_budget_id"-->
            <!--              :disabled-tooltip="-->
            <!--                workspaceStore.workspace?.shared_budgets.length-->
            <!--                  ? `To turn on optimization for this campaign you need to move it into a budget`-->
            <!--                  : `To turn on optimization for this campaign you need to set up a budget`-->
            <!--              "-->
            <!--            >-->
            <!--              <span :class="managed ? `` : `opacity-30`">Autopilot</span>-->
            <!--            </toggle>-->
          </div>

          <!--        <ad-channel-icon-->
          <!--          class="h-7 w-7"-->
          <!--            v-for="campaign in props.campaign.campaigns"-->
          <!--          :key="campaign.id"-->
          <!--          :type="campaign.ad_channel.type"-->
          <!--        ></ad-channel-icon>-->
        </div>
        <div>
          <campaign-menu
            :campaign="props.campaign"
            @open-metrics="openMetrics"
          ></campaign-menu>
        </div>
      </div>
    </div>
    <div
      class="flex grow items-center justify-center px-6 pb-2 pt-4 text-sm text-zinc-300"
    >
      <div class="h-full w-full">
        <graph
          v-if="workspaceStore.campaigns_timeframe"
          :data="data"
          :currency="props.campaign.currency"
          :timeframe="workspaceStore.campaigns_timeframe"
          :uuid="props.uuid ?? `campaign-block-${props.campaign.id}`"
        ></graph>
      </div>
      <!--      <img :src="graph_empty" class="w-full" alt="graph" />-->
    </div>
    <div class="flex h-14 w-full items-center justify-between pl-5 pr-3.5">
      <!--      <popover>-->
      <!--        <template #trigger>-->

      <effective-daily-budget
        :campaign="props.campaign"
      ></effective-daily-budget>
      <!--        </template>-->
      <!--      </popover>-->
      <div class="flex grow items-center justify-end">
        <metric-forecast
          :campaign="props.campaign"
          :group="props.group"
        ></metric-forecast>
      </div>
    </div>
    <!--    <pre-->
    <!--      class="text-xs"-->
    <!--      v-for="campaign in props.campaign.campaigns"-->
    <!--      :key="campaign.id"-->
    <!--      >{{ campaign.metrics }}</pre-->
    <!--    >-->
  </div>
</template>
