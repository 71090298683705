<script setup lang="ts">
import { PropType, ref } from "vue";

import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import StyledButton from "@/components/form/styled-button.vue";
import FormGroupOld from "@/components/form-group-old.vue";
import { Campaign } from "@/dtos/campaign.ts";
import { SharedBudget } from "@/dtos/shared-budget.ts";
import { targetMetricName } from "@/enums/target-metric.ts";
import { api } from "@/helpers/api.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const emit = defineEmits<{
  (e: "close"): void;
}>();
const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
});
const workspaceStore = useWorkspaceStore();
const currencyStore = useCurrencyStore();

const open = defineModel<boolean>();
const submitting = ref<boolean>(false);

const shared_budget_id = ref<number | undefined>(
  props.campaign.shared_budget_id,
);

type Errors = {
  shared_budget_id?: SharedBudget;
};

const errors = ref<Errors>({});

const validate = () => {
  validateSharedBudgetId();
};

const validateSharedBudgetId = () => {
  errors.value.shared_budget_id = undefined;
};

const submit = () => {
  if (submitting.value) {
    return;
  }
  submitting.value = true;

  validate();

  if (errors.value.shared_budget_id) {
    submitting.value = false;
    return;
  }
  workspaceStore.workspace!.campaigns.forEach((campaign_: Campaign) => {
    if (campaign_.id === props.campaign.id) {
      campaign_.shared_budget_id = shared_budget_id.value;
    }
  });
  api
    .put("workspace/move-campaign-into-group", {
      campaign_id: props.campaign.id,
      shared_budget_id: shared_budget_id.value,
    })
    .then(() => {
      workspaceStore.reload(workspaceStore.workspace!);
      submitting.value = false;
      open.value = false;
    });
};
</script>

<template>
  <div class="dialog-box">
    <dialog-titlebar
      @close="emit('close')"
      title="Move campaign into group"
    ></dialog-titlebar>
    <dialog-body>
      <div class="flex w-full min-w-[500px] flex-col space-y-5">
        <form-group-old>
          <template #label>
            <label
              for="name"
              :class="`form-label ${errors.shared_budget_id ? `invalid` : ``}`"
              >Shared budget</label
            ></template
          >
          <template #control>
            <select
              v-if="workspaceStore.workspace"
              class="input"
              v-model="shared_budget_id"
              @change="
                () => {
                  if (errors.shared_budget_id) {
                    validateSharedBudgetId();
                  }
                }
              "
            >
              <option
                v-for="shared_budget in workspaceStore.workspace
                  ?.shared_budgets ?? []"
                :key="shared_budget.id"
                :value="shared_budget.id"
              >
                {{ shared_budget.name }}{{ !!shared_budget.name ? ` ` : ``
                }}{{
                  `(${targetMetricName(shared_budget.target_metric)}, ${currencyStore.format(
                    shared_budget.monthly_budget_ceiling,
                    workspaceStore.workspace.currency,
                  )})`
                }}
              </option>
            </select>
          </template>
          <template #error>
            <span class="form-error" v-if="errors.shared_budget_id">{{
              errors.shared_budget_id
            }}</span>
          </template>
        </form-group-old>
        <div class="flex w-full flex-col items-start space-y-6 pt-4">
          <styled-button :submitting="submitting" @click="submit">
            Submit
          </styled-button>
        </div>
      </div>
    </dialog-body>
  </div>
</template>
