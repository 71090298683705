<script setup lang="ts">
import { CaretDownIcon } from "@radix-icons/vue";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "radix-vue";
import { ref } from "vue";

import { CampaignsTimeframe } from "@/enums/campaigns-timeframe.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();

const isOpen = ref<boolean>(false);
</script>
<template>
  <DropdownMenuRoot
    :open="isOpen"
    @update:open="
      (payload: boolean) => {
        isOpen = payload;
      }
    "
    :modal="false"
  >
    <DropdownMenuTrigger
      class="hover:bg-zinc-100x group relative inline-flex cursor-pointer items-center justify-center rounded-full outline-none transition-colors"
      aria-label="Customise options"
    >
      <div
        class="flex cursor-pointer items-center space-x-2 transition-all duration-75 hover:text-white"
      >
        <span
          v-if="
            workspaceStore.campaigns_timeframe ===
            CampaignsTimeframe.PAST_24_HOURS
          "
          >Past 24 hours</span
        >
        <span
          v-else-if="
            workspaceStore.campaigns_timeframe ===
            CampaignsTimeframe.PAST_7_DAYS
          "
          >Past week</span
        >
        <span
          v-else-if="
            workspaceStore.campaigns_timeframe === CampaignsTimeframe.PAST_MONTH
          "
          >Past month</span
        >
        <span
          v-else-if="
            workspaceStore.campaigns_timeframe ===
            CampaignsTimeframe.PAST_QUARTER
          "
          >Past quarter</span
        >
        <span
          v-else-if="
            workspaceStore.campaigns_timeframe === CampaignsTimeframe.PAST_YEAR
          "
          >Past year</span
        >
        <CaretDownIcon class="h-5 w-auto"></CaretDownIcon>
      </div>
    </DropdownMenuTrigger>

    <DropdownMenuPortal>
      <DropdownMenuContent
        class="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade flex flex-col space-y-1 rounded-lg border border-zinc-800 bg-black px-1.5 py-2.5 shadow-[0_2px_30px_-15px_rgb(0,0,0,0.2)] outline outline-4 will-change-[opacity,transform]"
        :side-offset="10"
        align="end"
      >
        <DropdownMenuItem
          :value="CampaignsTimeframe.PAST_7_DAYS"
          class="group relative flex cursor-pointer select-none items-center justify-end rounded-full bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          @click="
            () => {
              workspaceStore.setCampaignsTimeframe(
                CampaignsTimeframe.PAST_7_DAYS,
              );
            }
          "
        >
          Past week
        </DropdownMenuItem>
        <DropdownMenuItem
          :value="CampaignsTimeframe.PAST_MONTH"
          class="group relative flex cursor-pointer select-none items-center justify-end rounded-full bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          @click="
            () => {
              workspaceStore.setCampaignsTimeframe(
                CampaignsTimeframe.PAST_MONTH,
              );
            }
          "
        >
          Past month
        </DropdownMenuItem>
        <DropdownMenuItem
          :value="CampaignsTimeframe.PAST_QUARTER"
          class="group relative flex cursor-pointer select-none items-center justify-end rounded-full bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          @click="
            () => {
              workspaceStore.setCampaignsTimeframe(
                CampaignsTimeframe.PAST_QUARTER,
              );
            }
          "
        >
          Past quarter
        </DropdownMenuItem>
        <DropdownMenuItem
          :value="CampaignsTimeframe.PAST_YEAR"
          class="group relative flex cursor-pointer select-none items-center justify-end rounded-full bg-white px-5 py-2.5 text-right leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
          @click="
            () => {
              workspaceStore.setCampaignsTimeframe(
                CampaignsTimeframe.PAST_YEAR,
              );
            }
          "
        >
          Past year
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenuRoot>
</template>
