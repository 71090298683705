<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";
import LearnMoreAbout from "@/app/docs/learn-more-about.vue";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      Configurable budgets that are shared by multiple cross-platform campaigns.
      Campaigns within each shared budget compete for utilization based on
      current performance.
    </p>
    <p>
      Once you have created a shared budget and assigned campaigns to it,
      Charter will allocate the shared budget between campaigns over time to
      maximize the target metric that you selected for that shared budget.
    </p>
    <learn-more-about
      :route="{
        name: 'docs-target-metrics',
      }"
      >Target Metrics</learn-more-about
    >
    <h3>Basic configuration</h3>
    <p>...</p>
    <h3>Campaign thresholds</h3>
    <p>...</p>
    <h3>Budget change approval</h3>
    <p>...</p>
    <hr />
    <h1>{{ page.title }}</h1>
    <p>
      Charter’s shared budgets allow you to organise campaigns by different
      objectives, and use Charter’s algorithm to work towards those objectives.
      Each shared budget has its own maximum budget that Charter works within,
      and which you can adjust at any time.
    </p>
    <p>
      For example, you may have 2 shared budgets, one for brand awareness where
      you are wanting to optimise for Reach, and another for sales where you are
      wanting to optimise for Revenue. You set the Reach budget to $2,000/month
      and the Revenue budget to $1,000/month.
    </p>
    <p>
      Shared budgets can include campaigns from different ad channels - this is
      a key part of Charter’s magic.
    </p>
    <p>
      For example, within your Reach shared budget you have 2 Meta campaigns and
      1 Google campaign. Charter will continuously monitor how each of these
      campaigns are performing with the goal of maximising Reach, and adjust the
      portion of shared budget each campaign receives.
    </p>
    <h3>Subheading</h3>
  </div>
</template>
