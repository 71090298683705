<script setup lang="ts">
import { GearIcon, LightningBoltIcon } from "@radix-icons/vue";
import { computed, PropType, ref } from "vue";

import BudgetChangeApprovalDialog from "@/app/workspace/approval/budget-change-approval-dialog.vue";
import CreateBudgetDialog from "@/app/workspace/campaigns/shared-budgets/create-shared-budget-dialog.vue";
import MetricForecast from "@/app/workspace/campaigns/shared-budgets/metric-forecast.vue";
import RemoveBudgetDialog from "@/app/workspace/campaigns/shared-budgets/remove-shared-budget-dialog.vue";
import DialogWrapper from "@/components/dialog-wrapper.vue";
import { BudgetingAlgorithmRun } from "@/dtos/budgeting-algorithm-run.ts";
import { SharedBudget } from "@/dtos/shared-budget.ts";
import { BudgetingAlgorithmRunStatus } from "@/enums/budgeting-algorithm-run-status.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const currencyStore = useCurrencyStore();
const workspaceStore = useWorkspaceStore();

const props = defineProps({
  group: {
    type: Object as PropType<SharedBudget | undefined>,
    required: false,
    default: undefined,
  },
});
const approval_open = ref<boolean>(false);
const manage_open = ref<boolean>(false);
const remove_open = ref<boolean>(false);

const pending_budgeting_algorithm_run = computed<
  BudgetingAlgorithmRun | undefined
>(() => {
  if (!props.group) {
    return undefined;
  }
  for (let ii = 0; ii < props.group.runs.length; ii++) {
    if (props.group.runs[ii].status === BudgetingAlgorithmRunStatus.PENDING) {
      return props.group.runs[ii];
    }
  }
  return undefined;
});

console.log(props.group);
</script>
<template>
  <div
    class="mb-7 flex items-center justify-between bg-white/3 px-9 py-2"
    v-if="workspaceStore.workspace"
  >
    <div class="flex items-center justify-start space-x-8">
      <span class="font-medium" v-if="props.group?.name">{{
        props.group.name
      }}</span>
      <span class="font-medium" v-else-if="!props.group"
        >Unassigned campaigns</span
      >
      <div class="flex items-center justify-start space-x-7" v-if="props.group">
        <div
          v-if="props.group.effective_daily_budget !== undefined"
          class="ad-spend flex items-center space-x-2 font-mono text-sm text-purple-300/50"
        >
          <span class="font-semibold text-purple-300/75">{{
            currencyStore.format(
              props.group.effective_daily_budget * (365.25 / 12),
              workspaceStore.workspace.currency,
            )
          }}</span>
          <span>/</span>
          <span>{{
            currencyStore.format(
              props.group.monthly_budget_ceiling,
              workspaceStore.workspace.currency,
            )
          }}</span>
          <span> per month </span>
        </div>
        <div
          v-if="
            props.group.effective_daily_budget !== undefined &&
            props.group.utilization !== undefined
          "
          class="flex items-center space-x-2 font-mono text-sm text-purple-300/50"
        >
          <span class=""
            >{{ (props.group.utilization * 100).toFixed(2) }}%</span
          >
          <span> utilization</span>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-end space-x-12" v-if="props.group">
      <metric-forecast :group="props.group"></metric-forecast>
      <div class="flex items-center justify-between space-x-1">
        <div
          v-if="pending_budgeting_algorithm_run"
          class="cursor-pointer rounded-full p-3 opacity-40 hover:opacity-100"
          @click="
            () => {
              if (!props.group || props.group.id === 1) {
                return;
              }
              approval_open = true;
            }
          "
        >
          <LightningBoltIcon class="h-5 w-5"></LightningBoltIcon>
        </div>
        <div
          class="cursor-pointer rounded-full p-3 opacity-40 hover:opacity-100"
          @click="
            () => {
              if (!props.group || props.group.id === 1) {
                return;
              }
              manage_open = true;
            }
          "
        >
          <GearIcon class="h-5 w-5"></GearIcon>
        </div>
      </div>

      <dialog-wrapper v-model="approval_open">
        <budget-change-approval-dialog
          v-if="pending_budgeting_algorithm_run"
          :run="pending_budgeting_algorithm_run"
          @close="approval_open = false"
          @approved="
            () => {
              approval_open = false;
            }
          "
          @rejected="
            () => {
              approval_open = false;
            }
          "
        ></budget-change-approval-dialog>
      </dialog-wrapper>

      <dialog-wrapper v-model="manage_open">
        <create-budget-dialog
          :shared-budget="props.group"
          title="Shared budget settings"
          @close="manage_open = false"
          @remove="
            () => {
              manage_open = false;
              remove_open = true;
            }
          "
        ></create-budget-dialog>
      </dialog-wrapper>

      <dialog-wrapper v-model="remove_open">
        <remove-budget-dialog
          :shared-budget="props.group"
          @cancel="
            () => {
              remove_open = false;
              manage_open = true;
            }
          "
        ></remove-budget-dialog>
      </dialog-wrapper>
    </div>
  </div>
</template>
