<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";

import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import FormGroupOld from "@/components/form-group-old.vue";
import Spinner from "@/components/spinner.vue";
import { UserInit } from "@/dtos/user-init.ts";
import { api } from "@/helpers/api.ts";
import { useAccountStore } from "@/stores/account.ts";
import { useAuthStore } from "@/stores/auth.ts";

const emit = defineEmits<{
  (e: "close"): void;
}>();
const accountStore = useAccountStore();
const authStore = useAuthStore();
const router = useRouter();

const pending = ref<boolean>(false);
const complete = ref<boolean>(false);

const name = ref<string>("");

type Errors = {
  name?: string;
};

const errors = ref<Errors>({});

const validate = () => {
  validateName();
};

const validateName = () => {
  if (!name.value.length) {
    errors.value.name = "Please enter a name for the workspace";
  } else {
    errors.value.name = undefined;
  }
};

const submit = () => {
  if (pending.value) {
    return;
  }
  pending.value = true;

  validate();

  if (errors.value.name) {
    pending.value = false;

    return;
  }

  api
    .post("account/workspace", {
      account_id: accountStore.account!.id,
      name: name.value,
    })
    .then((data: unknown) => {
      const response = data as {
        init: UserInit;
        workspace_id: number;
      };

      authStore.load(response.init);
      router.push({
        name: "ad-channels",
        params: {
          workspace: response.workspace_id,
        },
      });

      pending.value = false;
      complete.value = true;

      emit("close");
    });
};
</script>

<template>
  <div class="dialog-box">
    <dialog-titlebar
      @close="emit('close')"
      title="Create workspace"
    ></dialog-titlebar>
    <dialog-body
      ><div class="flex flex-col space-y-4">
        <form-group-old>
          <template #label>
            <label
              for="name"
              :class="`form-label ${errors.name ? `invalid` : ``}`"
              >Name</label
            ></template
          >
          <template #control>
            <input
              name="website"
              :class="`h-10 text-center md:text-left ${errors.name ? `invalid` : ``}`"
              v-model="name"
              @input="
                () => {
                  if (errors.name) {
                    validateName();
                  }
                }
              "
            />
          </template>
          <template #error>
            <span class="form-error" v-if="errors.name">{{ errors.name }}</span>
          </template>
        </form-group-old>
        <div class="pb-1.5 pt-4">
          <button
            :class="`button relative w-full md:w-auto ${pending ? 'disabled' : ''}`"
            :disabled="pending"
            @click="submit"
          >
            <spinner v-if="pending" class="absolute fill-current"></spinner>
            <span :class="`${pending ? `opacity-0` : ``} text-lg`">
              Submit</span
            >
          </button>
        </div>
      </div>
    </dialog-body>
  </div>
</template>
