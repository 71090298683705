export enum TargetMetric {
  REVENUE = "revenue",
  AD_PROFIT = "ad-profit",
  REACH = "reach",
  IMPRESSIONS = "impressions",
  CLICKS = "clicks",
  UNIQUE_CLICKS = "unique-clicks",
  ROAS = "roas",
  COST_PER_PURCHASE = "cpp",
  AVERAGE_CONVERSION_REVENUE = "acr",
  COST_PER_MILLE = "cpm",
  COST_PER_UNIQUE_MILLE = "unique-cpm",
  COST_PER_CLICK = "cpc",
  COST_PER_UNIQUE_CLICK = "unique-cpc",
}

export const targetMetricName = (item: TargetMetric): string => {
  switch (item) {
    case TargetMetric.REVENUE:
      return "Revenue";
    case TargetMetric.AD_PROFIT:
      return "Ad profit";
    case TargetMetric.IMPRESSIONS:
      return "Impressions";
    case TargetMetric.REACH:
      return "Reach";
    case TargetMetric.CLICKS:
      return "Clicks";
    case TargetMetric.UNIQUE_CLICKS:
      return "Unique clicks";
    case TargetMetric.ROAS:
      return "Return on ad spend";
    case TargetMetric.AVERAGE_CONVERSION_REVENUE:
      return "Average purchase value";
    case TargetMetric.COST_PER_PURCHASE:
      return "Cost per purchase";
    case TargetMetric.COST_PER_MILLE:
      return "Cost per mille";
    case TargetMetric.COST_PER_UNIQUE_MILLE:
      return "Cost per unique mille";
    case TargetMetric.COST_PER_CLICK:
      return "Cost per click";
    case TargetMetric.COST_PER_UNIQUE_CLICK:
      return "Cost per unique click";
  }
};
