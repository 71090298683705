import { defineStore } from "pinia";
import { ref } from "vue";

import { Currency } from "@/dtos/currency.ts";
import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";

export const useCurrencyStore = defineStore("currency", () => {
  const currencies = ref<Currency[] | undefined>(undefined);

  const load = (currencies_: Currency[]) => {
    currencies.value = nullsToUndefined(currencies_);
  };
  const clear = () => {
    currencies.value = undefined;
  };

  const find = (currency_id: number): Currency | undefined => {
    return currencies.value!.find(
      (currency: Currency) => currency.id === currency_id,
    );
  };

  const format = (
    value: number,
    currency: Currency,
    locale?: string,
  ): string => {
    return Intl.NumberFormat(locale ?? [], {
      style: "currency",
      currency: currency.code.toUpperCase(),
    }).format(value);
  };

  return {
    currencies,
    load,
    clear,
    find,
    format,
  };
});
