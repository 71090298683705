<script setup lang="ts">
import FormError from "@/components/form/form-error.vue";

const props = defineProps({
  padded: {
    type: Boolean,
    required: false,
    default: true,
  },
  border: {
    type: Boolean,
    required: false,
    default: true,
  },
});
</script>
<template>
  <div
    :class="`${props.border ? `border-0 border-b-[1px] border-white/10` : ``}`"
  >
    <div
      :class="`${props.padded ? `px-[25px] pt-[14px] ${$slots.info || $slots.action ? ` pb-[5px]` : ` pb-[28px]`}` : ``}`"
    >
      <div class="text-lg font-medium">
        <slot name="title"></slot><slot name="label"></slot>
      </div>
      <div
        v-if="$slots.description"
        class="mt-[7px] text-sm font-light text-white/50"
      >
        <slot name="description"></slot>
      </div>
      <div v-if="$slots.controls" class="mt-[20px] text-base">
        <slot name="controls"></slot>
      </div>
      <form-error v-if="$slots.error">
        <slot name="error"></slot>
      </form-error>
    </div>
    <div
      v-if="$slots.info || $slots.action"
      class="mx-[1px] mb-[1px] flex h-[60px] items-center justify-between bg-gradient-to-t from-white/4 to-white/0 px-[28px]"
    >
      <div class="text-sm"><slot name="info"></slot></div>
      <slot name="action"></slot>
    </div>
  </div>
</template>
