<script setup lang="ts">
import { PropType } from "vue";

import Badge from "@/components/badge.vue";
import LoadingBar from "@/components/loading-bar.vue";
import { Campaign } from "@/dtos/campaign.ts";
import { SharedBudget } from "@/dtos/shared-budget.ts";
import { BadgeState } from "@/enums/badge-state.ts";
import { TargetMetric } from "@/enums/target-metric.ts";
import { formatNumber } from "@/helpers/formatting.ts";
import { useCurrencyStore } from "@/stores/currency.ts";

const currencyStore = useCurrencyStore();

const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
  group: {
    type: Object as PropType<SharedBudget>,
    required: false,
    default: undefined,
  },
});
</script>
<template>
  <badge
    class="ml-3 mr-3"
    v-if="
      props.group?.target_metric === TargetMetric.AD_PROFIT &&
      props.campaign.daily_ad_profit_forecast !== undefined
    "
    :state="
      props.campaign.daily_ad_profit_forecast > 0
        ? BadgeState.POSITIVE
        : BadgeState.NEGATIVE
    "
  >
    <div class="flex items-center justify-center space-x-1.5">
      <span
        >{{
          currencyStore.format(
            props.campaign.daily_ad_profit_forecast * (365.25 / 12),
            props.campaign.currency,
          )
        }}
        <span class="inline">monthly ad profit</span></span
      >
    </div>
  </badge>
  <badge
    class="ml-3 mr-3"
    v-else-if="
      (!props.group || props.group?.target_metric === TargetMetric.REVENUE) &&
      props.campaign.daily_revenue_forecast !== undefined
    "
    :state="
      props.campaign.daily_revenue_forecast > 0
        ? BadgeState.POSITIVE
        : BadgeState.NEGATIVE
    "
  >
    <div class="flex items-center justify-center space-x-1.5">
      <span
        >{{
          currencyStore.format(
            props.campaign.daily_revenue_forecast * (365.25 / 12),
            props.campaign.currency,
          )
        }}
        <span class="inline">monthly revenue</span></span
      >
    </div>
  </badge>
  <badge
    class="ml-3 mr-3"
    v-else-if="
      props.group?.target_metric === TargetMetric.IMPRESSIONS &&
      props.campaign.daily_impressions_forecast !== undefined
    "
    :state="
      props.campaign.daily_impressions_forecast > 0
        ? BadgeState.POSITIVE
        : BadgeState.NEGATIVE
    "
  >
    <div class="flex items-center justify-center space-x-1.5">
      <span
        >{{
          formatNumber(
            props.campaign.daily_impressions_forecast * (365.25 / 12),
            0,
          )
        }}
        <span class="inline">monthly impressions</span></span
      >
    </div>
  </badge>
  <badge
    class="ml-3 mr-3"
    v-else-if="
      props.group?.target_metric === TargetMetric.REACH &&
      props.campaign.daily_reach_forecast !== undefined
    "
    :state="
      props.campaign.daily_reach_forecast > 0
        ? BadgeState.POSITIVE
        : BadgeState.NEGATIVE
    "
  >
    <div class="flex items-center justify-center space-x-1.5">
      <span
        >{{
          formatNumber(props.campaign.daily_reach_forecast * (365.25 / 12), 0)
        }}
        <span class="inline">monthly reach</span></span
      >
    </div>
  </badge>
  <badge
    class="ml-3 mr-3"
    v-else-if="
      props.group?.target_metric === TargetMetric.CLICKS &&
      props.campaign.daily_clicks_forecast !== undefined
    "
    :state="
      props.campaign.daily_clicks_forecast > 0
        ? BadgeState.POSITIVE
        : BadgeState.NEGATIVE
    "
  >
    <div class="flex items-center justify-center space-x-1.5">
      <span
        >{{
          formatNumber(props.campaign.daily_clicks_forecast * (365.25 / 12), 0)
        }}
        <span class="inline">monthly clicks</span></span
      >
    </div>
  </badge>
  <badge
    class="ml-3 mr-3"
    v-else-if="
      props.group?.target_metric === TargetMetric.UNIQUE_CLICKS &&
      props.campaign.daily_unique_clicks_forecast !== undefined
    "
    :state="
      props.campaign.daily_unique_clicks_forecast > 0
        ? BadgeState.POSITIVE
        : BadgeState.NEGATIVE
    "
  >
    <div class="flex items-center justify-center space-x-1.5">
      <span
        >{{
          formatNumber(
            props.campaign.daily_unique_clicks_forecast * (365.25 / 12),
            0,
          )
        }}
        <span class="inline">monthly unique clicks</span></span
      >
    </div>
  </badge>
  <badge
    class="ml-3 mr-3"
    v-else-if="
      props.group?.target_metric === TargetMetric.ROAS &&
      props.campaign.roas_in_previous_week !== undefined
    "
    :state="
      props.campaign.roas_in_previous_week >= 1
        ? BadgeState.POSITIVE
        : BadgeState.NEGATIVE
    "
  >
    <div class="flex items-center justify-center space-x-1.5">
      <span
        >{{ formatNumber(props.campaign.roas_in_previous_week, 2) }}
        <span class="inline">ROAS</span></span
      >
    </div>
  </badge>
  <badge
    class="ml-3 mr-3"
    v-else-if="
      props.group?.target_metric === TargetMetric.COST_PER_PURCHASE &&
      props.campaign.daily_cpp_forecast !== undefined
    "
    :state="BadgeState.POSITIVE"
  >
    <div class="flex items-center justify-center space-x-1.5">
      <span
        >{{
          currencyStore.format(
            props.campaign.daily_cpp_forecast,
            props.campaign.currency,
          )
        }}
        <span class="inline">cost per conversion</span></span
      >
    </div>
  </badge>
  <badge
    class="ml-3 mr-3"
    v-else-if="
      props.group?.target_metric === TargetMetric.AVERAGE_CONVERSION_REVENUE &&
      props.campaign.daily_acr_forecast !== undefined
    "
    :state="BadgeState.POSITIVE"
  >
    <div class="flex items-center justify-center space-x-1.5">
      <span
        >{{
          currencyStore.format(
            props.campaign.daily_acr_forecast,
            props.campaign.currency,
          )
        }}
        <span class="inline">avg conversion revenue</span></span
      >
    </div>
  </badge>
  <badge v-else :state="BadgeState.INACTIVE" class="ml-3 mr-3">
    <div class="flex items-center space-x-3">
      <span>Collecting data</span>
      <div class="w-3"><loading-bar :speed="1.5"></loading-bar></div>
    </div>
  </badge>
</template>
