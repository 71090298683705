<script setup lang="ts"></script>
<template>
  <div>
    <div
      class="mb-6 flex flex-col items-center text-center font-mono text-2xl uppercase opacity-40"
      v-if="$slots.subtitle"
    >
      <slot name="subtitle"></slot>
    </div>
    <div
      class="mb-6 flex flex-col items-center text-center text-4xl font-medium"
      v-if="$slots.title"
    >
      <slot name="title"></slot>
    </div>
    <div
      class="mx-auto mb-11 flex max-w-3xl flex-col items-center text-center font-mono font-light"
      v-if="$slots.description"
    >
      <slot name="description"></slot>
    </div>
    <div class="mt-10 flex flex-col items-center" v-if="$slots.image">
      <slot name="image"></slot>
    </div>
  </div>
</template>
