<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
<template>
  <div class="flex flex-col items-center bg-white/2 py-20" id="demo-cta">
    <div
      class="hoxver:bg-white/10 bg-wxhite/5 z-[0] flex w-full flex-col items-center justify-center space-y-8 rounded-xl px-14 text-center sm:text-center"
    >
      <span class="block w-full font-sans text-6xl font-medium leading-tight">{{
        t("landing.demo-cta.title")
      }}</span>
      <span class="block w-full max-w-[800px] text-balance text-center">{{
        t("landing.demo-cta.description")
      }}</span>
      <div class="z-0 flex-col items-center space-y-6 pb-2 pt-6">
        <a
          target="_blank"
          href="https://cal.com/emilie-from-charter/1-1-with-emilie"
          class="extra large button h-[148px] w-full max-w-[600px] space-x-4 text-2xl"
        >
          <span>{{ t("landing.demo-cta.cta") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>
