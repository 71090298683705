<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>Charter’s algorithm can run either:</p>
    <p>
      Automatically - completely hands off, it’ll make the budget changes
      without you needing to do anything, and you can review changes in the
      algorithm run log. [Screenshot]
    </p>
    <p>or</p>
    <p>
      Manually - the algorithm will run as in automatically, but it won’t make
      the budget changes without your approval. You will automatically get email
      notifications for budget approval requests.
    </p>
    <p>You can set budget change approval within each shared budget.</p>
    <p>[Screenshot]</p>
    <p>
      When there’s a budget change to approve, you’ll get a email notification
      which you can click directly through to approve.
    </p>
    <p>[Screenshot]</p>
    <p>
      Or, within your dashboard, you can simply click the notification within
      the shared budget and approve from there.
    </p>
    <p>[Screenshot]</p>
  </div>
</template>
