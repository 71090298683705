<script setup lang="ts"></script>
<template>
  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <title />
    <path
      style="fill: #febd00"
      d="M202.33,53.44a80.7,80.7,0,0,0-18.8,22.06L22.29,353.58A81.62,81.62,0,0,0,51.87,464.8h0a81.62,81.62,0,0,0,111.22-29.58l138.59-239C271.1,152.32,226.49,88.29,202.33,53.44Z"
    />
    <circle style="fill: #25a84a" cx="92.69" cy="394.4" r="81.38" />
    <rect
      style="fill: #2e8add"
      height="484.21"
      rx="81.38"
      ry="81.38"
      transform="translate(751.31 309.71) rotate(150)"
      width="162.76"
      x="252.78"
      y="13.4"
    />
  </svg>
</template>
