<script setup lang="ts">
import { CaretSortIcon } from "@radix-icons/vue";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "radix-vue";
import { ref } from "vue";
import { useRoute } from "vue-router";

import CreateWorkspaceDialog from "@/app/workspace/create-workspace-dialog.vue";
import DialogWrapper from "@/components/dialog-wrapper.vue";
import NumberBadge from "@/components/number-badge.vue";
import { AccountRole } from "@/dtos/account-role.ts";
import { AccountRoles } from "@/enums/account-roles.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const toggleState = ref<boolean>(false);

const workspaceStore = useWorkspaceStore();
const route = useRoute();

const create_workspace_dialog_open = ref<boolean>(false);
</script>
<template>
  <div>
    <DropdownMenuRoot
      :open="toggleState"
      @update:open="
        (payload: boolean) => {
          toggleState = payload;
        }
      "
      :modal="false"
    >
      <DropdownMenuTrigger
        :class="`group flex h-[34px] w-full items-center justify-between space-x-2 rounded-lg border px-5 transition-all duration-75 ${toggleState ? `border-white/20 text-white` : `border-white/10 text-white/40 hover:border-white/20 hover:text-white`}`"
        aria-label="Change workspace"
      >
        <span>{{ workspaceStore.workspace?.name ?? "Default workspace" }}</span>
        <CaretSortIcon icon="radix-icons:chevron-down" class="h-4 w-4" />
      </DropdownMenuTrigger>

      <DropdownMenuPortal>
        <DropdownMenuContent
          class="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade flex min-w-[278px] flex-col space-y-1 rounded-lg bg-white px-1.5 pb-3.5 pt-2.5 shadow-[0_2px_30px_-15px_rgb(0,0,0,0.2)] outline-none will-change-[opacity,transform] dark:border dark:border-zinc-800 dark:bg-black"
          :side-offset="10"
          align="start"
        >
          <div
            v-for="(
              workspacesInAccount, i
            ) in workspaceStore.acceptedWorkspacesInAccounts"
            :key="workspacesInAccount.account.id"
          >
            <div v-if="i >= 1">
              <DropdownMenuSeparator class="mx-3 my-2 h-[1px] bg-zinc-100" />
            </div>
            <div
              v-if="
                workspacesInAccount.account.name ??
                workspacesInAccount.account.roles.find(
                  (role: AccountRole) => role.role === AccountRoles.OWNER,
                )?.user.email
              "
              class="px-5 pb-3 pt-2 text-xs font-medium uppercase opacity-30"
            >
              <span>{{
                workspacesInAccount.account.name ??
                workspacesInAccount.account.roles.find(
                  (role: AccountRole) => role.role === AccountRoles.OWNER,
                )?.user.email
              }}</span>
            </div>
            <div class="flex flex-col space-y-2">
              <DropdownMenuItem
                v-for="workspace_ in workspacesInAccount.workspaces"
                :key="workspace_.id"
                :value="workspace_.name"
                as-child
              >
                <router-link
                  :to="{
                    name: route.name!,
                    params: {
                      ...route.params,
                      workspace: workspace_.id,
                    },
                  }"
                  class="group relative flex select-none items-center space-x-3 rounded-full bg-white px-5 py-2.5 leading-none outline-none hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
                >
                  <span>{{ workspace_.name ?? "Default workspace" }}</span>
                  <number-badge
                    :n="
                      workspaceStore.notificationsForWorkspace(workspace_.id)
                        ?.total
                    "
                  ></number-badge>
                </router-link>
              </DropdownMenuItem>
            </div>
          </div>
          <div class="pt-3">
            <DropdownMenuSeparator
              class="mx-3 my-1.5 h-[1px] bg-zinc-100 dark:bg-zinc-800"
            />
          </div>
          <div>
            <DropdownMenuItem value="Create workspace" as-child>
              <span
                @click="create_workspace_dialog_open = true"
                class="group relative flex cursor-pointer select-none items-center rounded-full bg-white px-5 py-2.5 leading-none outline-none transition-colors hover:bg-zinc-100 data-[disabled]:pointer-events-none dark:bg-black dark:hover:bg-zinc-900"
                >Create workspace</span
              >
            </DropdownMenuItem>
          </div>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenuRoot>

    <dialog-wrapper v-model="create_workspace_dialog_open">
      <create-workspace-dialog
        v-if="workspaceStore.workspace"
        title="Create Workspace"
        @close="create_workspace_dialog_open = false"
      ></create-workspace-dialog>
    </dialog-wrapper>
  </div>
</template>
