<script setup lang="ts">
import { ref } from "vue";

import AdChannelIcon from "@/app/ad-channel-icon.vue";
import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import FormGroupOld from "@/components/form-group-old.vue";
import LinkedinIcon from "@/components/icons/linkedin-icon.vue";
import XIcon from "@/components/icons/x-icon.vue";
import Toggle from "@/components/toggle.vue";
import { AdChannelType } from "@/enums/ad-channel-type.ts";
import { api } from "@/helpers/api.ts";
import regex from "@/helpers/regex.ts";
import { useInternationalisationStore } from "@/stores/internationalisation.ts";

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "complete"): void;
}>();

const internationalisationStore = useInternationalisationStore();

const pending = ref<boolean>(false);
const complete = ref<boolean>(false);

const email = ref<string>("");
const website = ref<string>("");

const meta = ref<boolean>(false);
const meta_spend = ref<string>("");
const google = ref<boolean>(false);
const google_spend = ref<string>("");

type Errors = {
  meta_spend?: string;
  google_spend?: string;
  email?: string;
  website?: string;
};

const errors = ref<Errors>({});

const validate = () => {
  validateEmail();
  validateMetaSpend();
  validateGoogleSpend();
  validateWebsite();
};

const validateWebsite = () => {
  if (!website.value.length) {
    errors.value.website = "Please enter your website URL";
  } else {
    errors.value.website = undefined;
  }
};

const validateMetaSpend = () => {
  errors.value.meta_spend = undefined;
};

const validateGoogleSpend = () => {
  errors.value.google_spend = undefined;
};

const validateEmail = () => {
  if (!email.value.length) {
    errors.value.email = "Please enter your email address";
  } else if (email.value.trim().length && !regex.email.test(email.value)) {
    errors.value.email = "Please enter a valid email address";
  } else {
    errors.value.email = undefined;
  }
};

const submit = () => {
  if (pending.value) {
    return;
  }
  pending.value = true;

  validate();

  if (
    errors.value.website ||
    errors.value.meta_spend ||
    errors.value.google_spend ||
    errors.value.email
  ) {
    pending.value = false;

    return;
  }

  api
    .post("https://api1.usecharter.io/application", {
      country_id: internationalisationStore.country!.id,
      company_url: website.value,
      email: email.value,

      meta: meta.value,
      meta_spend: meta_spend.value,
      google: google.value,
      google_spend: google_spend.value,
    })
    .then(() => {
      pending.value = false;
      complete.value = true;
      emit("complete");
    })
    .catch(() => {
      pending.value = false;
    });
};
</script>

<template>
  <div class="dialog-box">
    <dialog-titlebar
      @close="emit('cancel')"
      title="Join our beta program"
    ></dialog-titlebar>
    <dialog-body>
      <div
        class="flex w-full flex-col items-center justify-center space-y-4 p-1"
        v-if="complete"
      >
        <span class="text-lg font-semibold"
          >Thank you for applying to our beta program.</span
        >
        <span class="text-base"
          >We'll reach out via email with the next steps.</span
        >
        <div class="flex items-center justify-center space-x-4 pb-1.5 pt-0">
          <a
            href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fusecharter.io&text=Exciting+new+AI+powered+ad-tech+product%2C+check+it+out+✨"
            class="mt-2.5 flex items-center justify-center space-x-0.5 rounded-full bg-[#0072b1] px-5 text-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Share on Linked</span>
            <linkedin-icon class="h-6 w-6"></linkedin-icon>
          </a>
          <a
            href="https://twitter.com/intent/post?url=https%3A%2F%2Fusecharter.io&text=Exciting+new+AI+powered+ad-tech+product%2C+check+it+out+✨"
            class="mt-2.5 flex items-center justify-center space-x-2 rounded-full bg-black px-5 text-white dark:border dark:border-zinc-800"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Share on </span>
            <x-icon class="h-4 w-4"></x-icon>
          </a>
        </div>
      </div>
      <template v-else>
        <div class="flex flex-col space-y-4">
          <form-group-old>
            <template #label>
              <label
                for="email"
                :class="`form-label ${errors.email ? `invalid` : ``}`"
                >Email address</label
              ></template
            >
            <template #control>
              <input
                name="email"
                :class="`h-10 text-center md:text-left ${errors.email ? `invalid` : ``}`"
                v-model="email"
                @input="
                  () => {
                    if (errors.email) {
                      validateEmail();
                    }
                  }
                "
              />
            </template>
            <template #error>
              <span class="form-error" v-if="errors.email">{{
                errors.email
              }}</span>
            </template>
          </form-group-old>
          <form-group-old>
            <template #label>
              <label
                for="website"
                :class="`form-label ${errors.website ? `invalid` : ``}`"
                >Website URL</label
              ></template
            >
            <template #control>
              <input
                name="website"
                :class="`h-10 text-center md:text-left ${errors.website ? `invalid` : ``}`"
                v-model="website"
                @input="
                  () => {
                    if (errors.website) {
                      validateWebsite();
                    }
                  }
                "
              />
            </template>
            <template #error>
              <span class="form-error" v-if="errors.website">{{
                errors.website
              }}</span>
            </template>
          </form-group-old>
          <form-group-old>
            <template #label>
              <label
                for="email"
                :class="`form-label ${errors.email ? `invalid` : ``}`"
                >Current advertising channels</label
              ></template
            >
            <template #control
              ><div class="grid max-w-[400px] grid-cols-1 gap-3">
                <div class="flex items-center justify-between">
                  <toggle v-model="meta">
                    <div class="flex h-10 items-center space-x-2">
                      <ad-channel-icon
                        class="h-5 w-5"
                        :type="AdChannelType.META"
                      ></ad-channel-icon>
                      <span>Meta</span>
                    </div>
                  </toggle>
                  <div :class="`pl-5 ${meta ? `` : `hidden`}`">
                    <input
                      v-model="meta_spend"
                      class="w-full max-w-[180px] py-1"
                      placeholder="Monthly spend"
                    />
                  </div>
                </div>
                <div class="flex items-center justify-between">
                  <toggle v-model="google">
                    <div class="flex h-8 items-center space-x-2">
                      <ad-channel-icon
                        class="h-5 w-5"
                        :type="AdChannelType.GOOGLE"
                      ></ad-channel-icon>
                      <span>Google</span>
                    </div>
                  </toggle>
                  <div :class="`pl-5 ${google ? `` : `hidden`}`">
                    <input
                      v-model="google_spend"
                      class="w-full max-w-[180px] py-1"
                      placeholder="Monthly spend"
                    />
                  </div>
                </div>
              </div>
            </template>
          </form-group-old>
          <span class="block w-full max-w-[380px] pt-2 text-sm text-zinc-700"
            >We'll send you updates via email.</span
          >
          <div class="pb-1.5 pt-2">
            <button
              :class="`button w-full md:w-auto ${pending ? 'disabled' : ''}`"
              :disabled="pending"
              @click="submit"
            >
              Join
            </button>
          </div>
        </div>
      </template>
    </dialog-body>
  </div>
</template>
