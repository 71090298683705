<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      If you want to give Charter’s algorithm boundaries in addition to your
      shared budgets, you can use thresholds. Thresholds are maximum/minimums
      that you can set for Charter to operate within.
    </p>
    <p>
      For example, if you set a maximum CPC of $2, Charter will automatically
      scale down campaigns that exceed a CPC of $2.
    </p>
    <p>The thresholds you can set for shared budgets are:</p>
    <p>Return on ad spend - ROAS</p>
    <p>Cost per purchase - CPP</p>
    <p>Cost per mille - CPM</p>
    <p>Cost per click - CPC</p>
    <p>
      Is there a threshold metric you’d like to use but isn’t currently listed?
      Drop us a message to let us know!
    </p>
  </div>
</template>
