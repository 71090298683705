<script setup lang="ts">
import { PropType } from "vue";

import BingAdsIcon from "@/components/icons/bing-icon.vue";
import GoogleAdsIcon from "@/components/icons/google-ads-icon.vue";
import LinkedinIcon from "@/components/icons/linkedin-icon.vue";
import MetaIcon from "@/components/icons/meta-icon.vue";
import PinterestIcon from "@/components/icons/pinterest-icon.vue";
import RedditIcon from "@/components/icons/reddit-icon.vue";
import SnapchatIcon from "@/components/icons/snapchat-icon.vue";
import TiktokIcon from "@/components/icons/tiktok-icon.vue";
import XIcon from "@/components/icons/x-icon.vue";
import YoutubeIcon from "@/components/icons/youtube-icon.vue";
import { AdChannelType } from "@/enums/ad-channel-type.ts";

const props = defineProps({
  type: {
    type: String as PropType<AdChannelType>,
    required: true,
  },
});
</script>
<template>
  <meta-icon v-if="props.type === AdChannelType.META"></meta-icon>
  <google-ads-icon
    v-else-if="props.type === AdChannelType.GOOGLE"
  ></google-ads-icon>
  <tiktok-icon
    v-else-if="props.type === AdChannelType.TIKTOK_ADS"
  ></tiktok-icon>
  <linkedin-icon
    v-else-if="props.type === AdChannelType.LINKEDIN_ADS"
  ></linkedin-icon>
  <reddit-icon
    v-else-if="props.type === AdChannelType.REDDIT_ADS"
  ></reddit-icon>
  <youtube-icon
    v-else-if="props.type === AdChannelType.YOUTUBE_ADS"
  ></youtube-icon>
  <x-icon v-else-if="props.type === AdChannelType.TWITTER_ADS"></x-icon>
  <pinterest-icon
    v-else-if="props.type === AdChannelType.PINTEREST_ADS"
  ></pinterest-icon>
  <snapchat-icon
    v-else-if="props.type === AdChannelType.SNAPCHAT"
  ></snapchat-icon>
  <bing-ads-icon
    v-else-if="props.type === AdChannelType.BING_ADS"
  ></bing-ads-icon>
</template>
