<script setup lang="ts"></script>
<template>
  <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
    <title />
    <g data-name="7-snapchat" id="_7-snapchat">
      <rect style="fill: #fffc40" height="64" rx="11.2" ry="11.2" width="64" />
      <path
        style="fill: #fff"
        d="M32,54C36.73,54,37.93,51,40.73,50c2.1-.75,5.09-.38,6.46-.14a.79.79,0,0,0,.91-.62l.43-1.91a.81.81,0,0,1,.75-.62c4.85-.23,5.69-1.61,5.75-2.43a.8.8,0,0,0-.53-.77c-10.73-3.89-10.19-8.48-9.14-10.65a2.5,2.5,0,0,1,1.9-1.34c4.21-.65,3.74-5.15,1.21-3.79-2.74,1.47-4.2.4-4.2.4V21.67C44.27,14.8,38.78,10,32,10h0c-6.78,0-12.27,4.76-12.27,11.63v6.49s-1.46,1.07-4.2-.4c-2.53-1.36-3,3.14,1.21,3.79a2.5,2.5,0,0,1,1.9,1.34c1.05,2.17,1.59,6.76-9.14,10.65a.8.8,0,0,0-.53.77c.06.82.9,2.2,5.75,2.43a.81.81,0,0,1,.75.62l.43,1.91a.79.79,0,0,0,.91.62c1.37-.24,4.36-.61,6.46.14C26.07,51,27.27,54,32,54Z"
      />
      <path
        style="fill: #231f20"
        d="M32,54.56a9.59,9.59,0,0,1-6.46-2.45,9.12,9.12,0,0,0-2.48-1.51c-2-.72-5-.32-6.15-.12a1.4,1.4,0,0,1-1.6-1.07l-.43-1.91a.2.2,0,0,0-.19-.16c-5.06-.24-6.23-1.76-6.32-3A1.38,1.38,0,0,1,9.3,43c4.91-1.78,8-4,9-6.29a4.29,4.29,0,0,0-.15-3.54,1.88,1.88,0,0,0-1.45-1,4.07,4.07,0,0,1-3.56-2.74,2,2,0,0,1,.56-2.17,1.94,1.94,0,0,1,2.17,0,4,4,0,0,0,3.31.55V21.67c0-7,5.53-12.23,12.87-12.23s12.87,5.25,12.87,12.23V27.8a4.06,4.06,0,0,0,3.31-.56,1.94,1.94,0,0,1,2.17,0,2,2,0,0,1,.56,2.17,4.07,4.07,0,0,1-3.56,2.74,1.88,1.88,0,0,0-1.45,1,4.29,4.29,0,0,0-.15,3.54c1,2.33,4,4.51,9,6.29a1.38,1.38,0,0,1,.93,1.37c-.09,1.23-1.26,2.75-6.32,3a.2.2,0,0,0-.19.16l-.43,1.91a1.4,1.4,0,0,1-1.6,1.07c-1.18-.2-4.16-.6-6.15.12a9.12,9.12,0,0,0-2.48,1.51A9.59,9.59,0,0,1,32,54.56ZM20.15,49a10.11,10.11,0,0,1,3.32.49,10.69,10.69,0,0,1,2.8,1.68,8.53,8.53,0,0,0,11.46,0,10.69,10.69,0,0,1,2.8-1.68,14.72,14.72,0,0,1,6.76-.17.2.2,0,0,0,.23-.16L48,47.23a1.4,1.4,0,0,1,1.31-1.09c4.69-.22,5.14-1.49,5.17-1.88,0,0,0-.12-.14-.15-5.31-1.93-8.56-4.27-9.65-7a5.44,5.44,0,0,1,.18-4.52A3.1,3.1,0,0,1,47.17,31a2.88,2.88,0,0,0,2.58-1.86.91.91,0,0,0-.15-.91c-.17-.14-.48-.1-.85.1-3,1.63-4.76.41-4.84.36l-.24-.18v-6.8c0-6.29-5-11-11.67-11s-11.67,4.74-11.67,11v6.8l-.24.18c-.08.05-1.81,1.27-4.84-.36-.37-.2-.68-.24-.85-.1a.91.91,0,0,0-.15.91A2.88,2.88,0,0,0,16.83,31a3.1,3.1,0,0,1,2.35,1.67,5.44,5.44,0,0,1,.18,4.52c-1.09,2.69-4.34,5-9.65,7-.09,0-.15.1-.14.15,0,.39.48,1.66,5.17,1.88a1.4,1.4,0,0,1,1.31,1.09l.43,1.91a.2.2,0,0,0,.23.16A20.46,20.46,0,0,1,20.15,49Z"
      />
    </g>
  </svg>
</template>
