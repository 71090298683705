<script setup lang="ts">
import { ExternalLinkIcon } from "@radix-icons/vue";
import { PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

const props = defineProps({
  route: {
    type: Object as PropType<RouteLocationRaw>,
    required: true,
  },
});
</script>
<template>
  <router-link
    :to="props.route"
    class="flex-inline w-auto items-center space-x-1 whitespace-nowrap font-normal text-[#A782D1] no-underline transition-all duration-75 hover:text-[#DDC3FF]"
  >
    <span><slot></slot></span>
    <ExternalLinkIcon class="relative -top-[1px] inline-block h-auto w-4" />
  </router-link>
</template>
