<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      Of course, one of the primary features of Laravel Vapor is atomic,
      zero-downtime deployments. Unlike many other features of Vapor,
      deployments are only initiated via the Vapor CLI. During deployment, Vapor
      will run the build steps of your vapor.yml file on the local machine that
      the deployment is running on. This might be your personal machine or a
      continuous integration platform.
    </p>
    <span>Observability on Vercel provides you with:</span>
    <ul>
      <li>Health and performance metrics about your website</li>
      <li>
        Transparency with security investigations and compliance reporting
      </li>
      <li>An operational and extra-visible web application system</li>
    </ul>
    <h3>Subheading</h3>
  </div>
</template>
