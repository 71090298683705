<script setup lang="ts">
import { ref } from "vue";
import { useRoute } from "vue-router";

import AdChannelIcon from "@/app/ad-channel-icon.vue";
import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import Spinner from "@/components/spinner.vue";
import { AdChannelType } from "@/enums/ad-channel-type.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const emit = defineEmits<{
  (e: "cancel"): void;
}>();

const workspaceStore = useWorkspaceStore();
const route = useRoute();

const connectingMeta = ref<boolean>(false);
const connectingGoogle = ref<boolean>(false);

const connectGoogle = () => {
  connectingGoogle.value = true;
  window.location.href =
    "https://accounts.google.com/o/oauth2/v2/auth?" +
    `client_id=${import.meta.env.VITE_GOOGLE_CLIENT_ID}` +
    `&redirect_uri=${window.location.origin}/oauth/google-ad-channel` +
    `&response_type=code` +
    `&access_type=offline` +
    `&include_granted_scopes=true` +
    `&enable_granular_consent=false` +
    `&prompt=select_account consent` +
    `&scope=https://www.googleapis.com/auth/adwords` +
    `&state=${route.query.setup ? `setup` : `connect`}|${workspaceStore.workspace!.id}`;
};
const connectMeta = () => {
  connectingMeta.value = true;
  window.location.href =
    "https://www.facebook.com/v21.0/dialog/oauth?" +
    `client_id=${import.meta.env.VITE_META_APP_ID}` +
    `&redirect_uri=${window.location.origin}/oauth/meta-ad-channel` +
    `&state=${route.query.setup ? `setup` : `connect`}|${workspaceStore.workspace!.id}` +
    `&auth_type=rerequest` +
    `&config_id=${import.meta.env.VITE_META_AD_CHANNEL_CONFIG_ID}`;
};
</script>

<template>
  <div class="dialog-box">
    <dialog-titlebar
      @close="emit('cancel')"
      title="Select an ad channel to connect"
    ></dialog-titlebar>
    <dialog-body
      ><div class="mb-1 grid grid-cols-3 gap-x-7 gap-y-6">
        <div class="flex items-center justify-center">
          <div class="relative flex items-center justify-center">
            <spinner v-if="connectingMeta" class="absolute h-10 w-10"></spinner>
            <ad-channel-icon
              @click="connectMeta"
              :type="AdChannelType.META"
              class="h-24 w-24 cursor-pointer transition-all"
            />
          </div>
        </div>
        <div class="flex items-center justify-center">
          <div class="relative flex items-center justify-center">
            <spinner
              v-if="connectingGoogle"
              class="absolute h-10 w-10"
            ></spinner>
            <ad-channel-icon
              @click="connectGoogle"
              :type="AdChannelType.GOOGLE"
              class="h-24 w-24 cursor-pointer transition-all"
            />
          </div>
        </div>
      </div>
    </dialog-body>
  </div>
</template>
