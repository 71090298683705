<script setup lang="ts">
import { PropType } from "vue";

import { BudgetingAlgorithmRun } from "@/dtos/budgeting-algorithm-run.ts";
import { formatNumber } from "@/helpers/formatting.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const props = defineProps({
  run: {
    type: Object as PropType<BudgetingAlgorithmRun>,
    required: true,
  },
});

const workspaceStore = useWorkspaceStore();
const currencyStore = useCurrencyStore();
</script>
<template>
  <table class="w-full" v-if="workspaceStore.workspace">
    <thead>
      <tr>
        <th style="white-space: nowrap; vertical-align: top"></th>
        <th
          class="pb-2 text-center text-sm font-light uppercase opacity-50"
          style="width: 25%; vertical-align: top"
        >
          Spend
        </th>
        <th
          class="pb-2 text-center text-sm font-light uppercase opacity-50"
          style="width: 25%; vertical-align: top"
        >
          Utilization
        </th>
        <th
          class="pb-2 text-center text-sm font-light uppercase opacity-50"
          style="width: 25%; vertical-align: top"
        >
          ROAS
        </th>
        <th
          class="whitespace-nowrap pb-2 text-right text-sm font-light uppercase opacity-50"
          style="width: 25%; vertical-align: top"
        >
          Ad profit forecast
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="opacity-50">
        <td
          style="white-space: nowrap; vertical-align: top"
          class="py-1 pr-10 text-sm font-medium opacity-50"
        >
          Before
        </td>
        <td
          class="py-1 text-center font-mono text-purple-300/75"
          style="width: 33%; vertical-align: top"
        >
          {{
            currencyStore.format(
              props.run.effective_daily_budget_before * (365.25 / 12),
              workspaceStore.workspace.currency,
            )
          }}
        </td>
        <td
          class="py-1 text-center font-mono text-purple-300/75"
          style="width: 33%; vertical-align: top"
        >
          {{ formatNumber(props.run.utilization_before * 100, 2) }}%
        </td>
        <td
          class="py-1 text-center font-mono text-emerald-400/75"
          style="width: 33%; vertical-align: top"
        >
          {{ formatNumber(props.run.roas_before, 2) }}
        </td>
        <td
          class="py-1 pb-2 text-right font-mono font-medium text-emerald-400"
          style="width: 33%; vertical-align: top"
        >
          {{
            currencyStore.format(
              (props.run.daily_ad_profit_forecast_before * 365.25) / 12,
              workspaceStore.workspace.currency,
            )
          }}
        </td>
      </tr>
      <tr>
        <td
          style="white-space: nowrap; vertical-align: top"
          class="py-1 pr-10 text-sm font-medium opacity-50"
        >
          After
        </td>
        <td
          class="py-1 text-center font-mono text-purple-300/75"
          style="width: 33%; vertical-align: top"
        >
          {{
            currencyStore.format(
              props.run.effective_daily_budget_after * (365.25 / 12),
              workspaceStore.workspace.currency,
            )
          }}
        </td>
        <td
          class="py-1 text-center font-mono text-purple-300/75"
          style="width: 33%; vertical-align: top"
        >
          {{ formatNumber(props.run.utilization_after * 100, 2) }}%
        </td>
        <td
          class="py-1 text-center font-mono text-emerald-400/75"
          style="width: 33%; vertical-align: top"
        >
          {{ formatNumber(props.run.roas_after, 2) }}
        </td>
        <td
          class="py-1 text-right font-mono font-medium text-emerald-400"
          style="width: 33%; vertical-align: top"
        >
          {{
            currencyStore.format(
              (props.run.daily_ad_profit_forecast_after * 365.25) / 12,
              workspaceStore.workspace.currency,
            )
          }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
