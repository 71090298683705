<script setup lang="ts">
import { PropType } from "vue";

const emit = defineEmits<{
  (e: "logo-click"): void;
}>();
import { RouteLocationRaw } from "vue-router";

import CharterIcon from "@/components/icons/charter-icon.vue";

const props = defineProps({
  fixed: {
    type: Boolean,
    required: false,
    default: false,
  },
  logoLink: {
    type: Object as PropType<RouteLocationRaw>,
    required: false,
    default: undefined,
  },
});
</script>
<template>
  <div>
    <div
      :class="`z-[100] flex h-20 w-full items-center ${props.fixed ? `fixed shadow-subtler` : ``}`"
    >
      <div class="flex h-full w-full grow justify-between pl-8 pr-3 lg:pr-5">
        <div class="flex flex-none items-center space-x-8">
          <router-link
            @click="emit('logo-click')"
            :to="props.logoLink ?? { name: 'workspaces' }"
            class="flex flex-none items-center space-x-2.5"
            ><charter-icon class="h-7 w-7"></charter-icon>
            <span class="select-none font-sans text-3xl font-semibold"
              >Charter</span
            >
          </router-link>
          <slot name="left"></slot>
        </div>
        <slot name="right"></slot>
      </div>
    </div>
    <div v-if="props.fixed" class="h-28"></div>
  </div>
</template>
