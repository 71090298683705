<script setup lang="ts">
import {
  ToastDescription,
  ToastProvider,
  ToastRoot,
  ToastTitle,
  ToastViewport,
} from "radix-vue";
import { PropType } from "vue";

import { ToastVariant } from "@/enums/toast-variant.ts";

const open = defineModel<boolean>();

const props = defineProps({
  variant: {
    type: String as PropType<ToastVariant>,
    required: false,
    default: ToastVariant.NEUTRAL,
  },
  duration: {
    type: Number,
    required: false,
    default: 4000,
  },
  title: { type: String, required: false, default: "Oops" },
  description: {
    type: String,
    required: false,
    default: "Something went wrong",
  },
  shortDescription: {
    type: String,
    required: false,
    default: "Something went wrong",
  },
});
</script>

<template>
  <ToastProvider>
    <ToastRoot
      type="foreground"
      v-model:open="open"
      :duration="props.duration"
      :class="`${props.variant === ToastVariant.ERROR ? `border-rose-300 bg-rose-100 text-rose-600 dark:border-rose-600 dark:bg-rose-950 dark:text-rose-400` : props.variant === ToastVariant.SUCCESS ? `border-emerald-300 bg-emerald-100 text-emerald-600 dark:border-emerald-600 dark:bg-emerald-950 dark:text-emerald-400` : props.variant === ToastVariant.WARNING ? `border-amber-300 bg-amber-100 text-amber-600 dark:border-amber-600 dark:bg-amber-950 dark:text-amber-400` : `border-zinc-200 bg-zinc-50 dark:border-zinc-700 dark:bg-zinc-900`} data-[state=open]:anim ate-slideIn data-[state=closed]:animate-hide data-[swipe=end]:animate-swipeOut rounded-md border px-6 py-4 data-[swipe=cancel]:translate-x-0 data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:transition-[transform_200ms_ease-out]`"
    >
      <ToastTitle class="mb-1 font-bold">{{ props.title }}</ToastTitle>
      <ToastDescription class="break-words">{{
        props.description
      }}</ToastDescription>
    </ToastRoot>
    <ToastViewport
      class="fixed right-0 top-0 z-[2147483647] m-0 flex w-[440px] max-w-[100vw] list-none flex-col gap-[10px] p-[var(--viewport-padding)] outline-none [--viewport-padding:_25px]"
    />
  </ToastProvider>
</template>
