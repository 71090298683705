<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>[screenshots: select ad channel]</p>
    <p>
      If this is a new account, you’ll be prompted to connect an ad channel
      immediately after sign up. If you want to connect another ad channel to an
      existing account, simply click “Ad Channels” [screenshot] then “Connect ad
      channel”
    </p>
    <p>
      Meta: simply follow Meta’s instructions to connect your Meta account, then
      select the channel you want to connect from the drop down menu.
    </p>
    <p>Google: coming soon!</p>
    <p>Also in the pipeline: TikTok, Snapchat, Reddit, LinkedIn</p>
  </div>
</template>
