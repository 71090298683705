<script setup lang="ts">
import { onBeforeUnmount, onMounted, PropType, ref } from "vue";

import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import Spinner from "@/components/spinner.vue";
import { SharedBudget } from "@/dtos/shared-budget.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const props = defineProps({
  sharedBudget: {
    type: Object as PropType<SharedBudget>,
    required: false,
    default: undefined,
  },
});
const emit = defineEmits<{
  (e: "cancel"): void;
}>();
const workspaceStore = useWorkspaceStore();

const open = defineModel<boolean>();
const submitting = ref<boolean>(false);

const openListener = () => {
  open.value = true;
};
const closeListener = () => {
  open.value = false;
};
onMounted(() => {
  window.addEventListener(`open-remove-budget-dialog`, openListener);
  window.addEventListener(`close-remove-budget-dialog`, closeListener);
});
onBeforeUnmount(() => {
  window.removeEventListener(`open-remove-budget-dialog`, openListener);
  window.removeEventListener(`close-remove-budget-dialog`, closeListener);
});

const cancel = () => {
  emit("cancel");
  open.value = false;
};
const submit = () => {
  if (submitting.value) {
    return;
  }
  submitting.value = true;
  open.value = false;

  workspaceStore
    .removeSharedBudget(props.sharedBudget!.id, workspaceStore.workspace!)
    .then(() => {
      submitting.value = false;
    });
};
</script>

<template>
  <div>
    <dialog-titlebar
      @close="emit('cancel')"
      title="Delete budget"
    ></dialog-titlebar>
    <dialog-body>
      <div class="flex w-full max-w-[800px] flex-col space-y-5">
        <span>Are you sure?</span>
        <div class="flex w-full flex-row items-start space-x-3 pt-2">
          <button
            :class="`button destructive relative w-full md:w-auto ${submitting ? 'disabled' : ''}`"
            :disabled="submitting"
            @click="submit"
          >
            <spinner v-if="submitting" class="absolute fill-current"></spinner>
            <span :class="`${submitting ? `opacity-0` : ``} text-lg`">
              Confirm</span
            >
          </button>
          <button class="button relative w-full md:w-auto" @click="cancel">
            <span class="text-lg"> Cancel</span>
          </button>
        </div>
      </div>
    </dialog-body>
  </div>
</template>
