<script setup lang="ts">
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import AuthForm from "@/app/auth/auth-form.vue";
import PageLayout from "@/app/page-layout.vue";
import bg from "@/assets/bg.png";
import { AuthContext } from "@/enums/auth-context.ts";

const route = useRoute();
const router = useRouter();

const proceed = () => {
  if (route.query.to) {
    router.replace(JSON.parse(atob(route.query.to as string)));
  } else {
    router.replace({ name: "workspaces" });
  }
};

const back = () => {
  router.push({
    name: "home",
  });
};
const stage = ref(0);
</script>

<template>
  <div
    :style="`background-image: url(${bg}); background-size: cover; background-position: top; background-attachment: fixed; background-blend-mode: saturation;`"
  >
    <div class="flex min-h-screen dark:bg-[rgb(0,0,0)]/92">
      <page-layout
        :centered="true"
        :tabs="false"
        :has-back-button="true"
        @back="back"
      >
        <auth-form
          v-model="stage"
          @proceed="proceed"
          :auth-context="AuthContext.SIGN_IN"
          :email-auth-route="{
            name: 'sign-in-with-email',
          }"
        ></auth-form
      ></page-layout>
    </div>
  </div>
</template>
