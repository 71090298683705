<script setup lang="ts">
import { useNow } from "@vueuse/core";
import * as d3 from "d3";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {
  computed,
  onMounted,
  onUnmounted,
  onUpdated,
  PropType,
  ref,
} from "vue";

import { CampaignsTimeframe } from "@/enums/campaigns-timeframe.ts";

dayjs.extend(advancedFormat);

const props = defineProps({
  data: {
    type: Object as PropType<DataSeries[]>,
    required: true,
  },
  timeframe: {
    type: String as PropType<CampaignsTimeframe>,
    required: true,
  },
  uuid: {
    type: String,
    required: true,
  },
});

export interface DataSeries {
  label: string;
  positive_line_color: string;
  positive_text_color: string;
  negative_line_color?: string;
  negative_text_color?: string;
  isPositive?: (value: number) => boolean;
  values: DataPoint[];
  valueFormatter: (value: number) => string | number;
  current_value?: DataPoint;
  current_value_label?: string;
  muted: boolean;
  y_axis_from?: number;
  y_axis_to?: number;
}
export interface PotentialDataPoint {
  date: Date;
  value?: number;
}
export interface DataPoint {
  date: Date;
  value: number;
  label_override?: string;
}

const now = useNow();
const end = computed(() => {
  return dayjs(now.value)
    .set("milliseconds", 0)
    .set("seconds", 0)
    .set("minutes", 0);
});
const start = computed(() => {
  const days = {
    [CampaignsTimeframe.PAST_7_DAYS]: 7,
    [CampaignsTimeframe.PAST_MONTH]: Math.floor(365.25 / 12),
    [CampaignsTimeframe.PAST_QUARTER]: Math.floor(365.25 / 4),
    [CampaignsTimeframe.PAST_YEAR]: Math.floor(365.25),
  };
  return end.value.subtract(days[props.timeframe], "days");
});

const chartContainer = ref<HTMLElement | undefined>();

const render = () => {
  if (!chartContainer.value) return;

  const margin = { top: 0, right: 0, bottom: 20, left: 0 };
  const width = chartContainer.value.offsetWidth - margin.left - margin.right;
  const height = chartContainer.value.offsetHeight - margin.top - margin.bottom;

  d3.select(chartContainer.value).select("svg").remove();

  const svg = d3
    .select(chartContainer.value)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  const xScale = d3
    .scaleTime()
    .domain([start.value, end.value])
    .range([0, width]);

  const gX = svg
    .append("g")
    .attr("class", "text-zinc-600")
    .attr("transform", `translate(0,${height})`)
    .call(
      d3
        .axisBottom<Date>(xScale)
        .ticks(
          props.timeframe === CampaignsTimeframe.PAST_7_DAYS
            ? d3.timeDay.every(1)
            : props.timeframe === CampaignsTimeframe.PAST_MONTH
              ? d3.timeMonday.every(1)
              : d3.timeMonth.every(1),
        )
        .tickFormat((date: Date): string => {
          const formatDay = {
            [CampaignsTimeframe.PAST_7_DAYS]: d3.timeFormat("%a"),
            [CampaignsTimeframe.PAST_MONTH]: d3.timeFormat("%b %d"),
            [CampaignsTimeframe.PAST_QUARTER]: d3.timeFormat("%b"),
            [CampaignsTimeframe.PAST_YEAR]: d3.timeFormat("%b"),
          }[props.timeframe];
          return formatDay(date);
        }),
    );
  gX.select(".domain").remove();
  gX.selectAll(".tick line").remove();

  svg
    .selectAll("xGrid")
    .data(
      props.timeframe === CampaignsTimeframe.PAST_7_DAYS
        ? d3.timeDay.range(start.value.toDate(), end.value.toDate())
        : props.timeframe === CampaignsTimeframe.PAST_MONTH
          ? d3.timeMonday.range(start.value.toDate(), end.value.toDate())
          : d3.timeMonth.range(start.value.toDate(), end.value.toDate()),
    )
    .join("line")
    .attr("x1", (d: Date) => xScale(d))
    .attr("x2", (d: Date) => xScale(d))
    .attr("y1", 0)
    .attr("y2", height)
    .attr("stroke", "#1c1c1c")
    .attr("stroke-width", 1);

  props.data.forEach((series) => {
    const yScale = d3
      .scaleLinear()
      .domain([
        series.y_axis_from ??
          Math.floor(d3.min(series.values, (d) => d.value)!),
        series.y_axis_to ?? Math.ceil(d3.max(series.values, (d) => d.value)!),
      ])
      .nice()
      .range([height, 0]);

    svg
      .append("path")
      .datum(series.values)
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", series.positive_line_color) // todo: check isPositive
      .attr("stroke-width", 1.4)
      .attr("opacity", series.muted ? 0.3 : 1)
      .attr(
        "d",
        d3
          .line<DataPoint>()
          .x((d: DataPoint) => xScale(d.date))
          .y((d: DataPoint) => yScale(d.value))
          .curve(d3.curveBasis),
      );
  });

  d3.selectAll(`.${props.uuid}`).remove();

  const tooltip = d3
    .select("body")
    .append("div")
    .attr("class", `tooltip ${props.uuid}`)
    .style("opacity", 0)
    .style("display", "none");

  const focusLine = svg
    .append("line")
    .attr("class", "focus-line")
    .style("opacity", 0)
    .attr("y1", 0)
    .attr("y2", height);

  const bisect = d3.bisector((d: DataPoint) => d.date).left;

  const handleMouseMove = (event: MouseEvent) => {
    const xMousePosition = xScale.invert(d3.pointer(event)[0]);
    const pointIndex = bisect(props.data[0].values, xMousePosition, 1);
    const prevPoint = props.data[0].values[pointIndex - 1];
    const nextPoint = props.data[0].values[pointIndex] ?? prevPoint;
    const point =
      +xMousePosition - +prevPoint.date > +nextPoint.date - +xMousePosition
        ? nextPoint
        : prevPoint;

    const parts = props.data.map((series) => {
      const seriesPoint = series.values.find(
        (p) => p.date.getTime() === point.date.getTime(),
      );
      return seriesPoint
        ? `<div class="font-mono flex items-center justify-between space-x-3 ${
            series.muted ? `opacity-40` : ``
          }">
            <span style="color: ${
              series.isPositive === undefined ||
              series.isPositive!(seriesPoint.value)
                ? series.positive_text_color
                : (series.negative_text_color ?? series.positive_text_color)
            }">${seriesPoint.label_override ?? series.label}</span>
            <span>${series.valueFormatter(seriesPoint.value)}</span>
          </div>`
        : "";
    });

    tooltip.html(
      `<div class="flex flex-col">
        <div class="font-semibold">${dayjs(point.date).format("Do MMM YYYY")}</div>
        ${parts.join("")}
      </div>`,
    );

    const tooltipWidth = 250;
    const pageWidth = document.body.clientWidth;
    let left = event.pageX + 10;
    if (event.pageX + tooltipWidth > pageWidth - margin.right) {
      left = event.pageX - tooltipWidth - 10;
    }

    tooltip.style("left", `${left}px`).style("top", `${event.pageY - 28}px`);
    focusLine.attr("x1", xScale(point.date)).attr("x2", xScale(point.date));
  };

  svg
    .append("rect")
    .attr("width", width)
    .attr("height", height)
    .style("fill", "none")
    .style("pointer-events", "all")
    .on("mouseover", () => {
      tooltip.style("opacity", 1).style("display", "block");
      focusLine.style("opacity", 0.2);
    })
    .on("mouseout", () => {
      tooltip.style("opacity", 0).style("display", "none");
      focusLine.style("opacity", 0);
    })
    .on("mousemove", handleMouseMove);
};

onMounted(() => {
  window.addEventListener("resize", render);
  render();
});
onUpdated(() => {
  render();
});
onUnmounted(() => {
  window.removeEventListener("resize", render);
});
</script>

<template>
  <div ref="chartContainer" class="chart-container"></div>
</template>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
svg {
  width: 100%;
  height: 100%;
}
</style>
