<script setup lang="ts"></script>
<template>
  <svg
    class="fill-current"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.6939 1.7847C23.102 1.37643 23.102 0.71449 22.6939 0.306219C22.2855 -0.102073 21.6236 -0.102073 21.2152 0.306219L11.5 10.0215L1.7847 0.306219C1.37643 -0.102073 0.71449 -0.102073 0.306219 0.306219C-0.102073 0.71449 -0.102073 1.37643 0.306219 1.7847L10.0215 11.5L0.306219 21.2152C-0.102073 21.6236 -0.102073 22.2855 0.306219 22.6939C0.71449 23.102 1.37643 23.102 1.7847 22.6939L11.5 12.9785L21.2152 22.6939C21.6236 23.102 22.2855 23.102 22.6939 22.6939C23.102 22.2855 23.102 21.6236 22.6939 21.2152L12.9785 11.5L22.6939 1.7847Z"
    />
  </svg>
</template>
