<script setup lang="ts">
import dayjs from "dayjs";
import { PropType } from "vue";

import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import { Campaign } from "@/dtos/campaign.ts";
import { Currency } from "@/dtos/currency.ts";
import { useCurrencyStore } from "@/stores/currency.ts";

const emit = defineEmits<{
  (e: "close"): void;
}>();
const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
  currency: {
    type: Object as PropType<Currency>,
    required: true,
  },
});
const currencyStore = useCurrencyStore();
</script>

<template>
  <div>
    <dialog-titlebar
      @close="emit('close')"
      title="Budget change history"
    ></dialog-titlebar>
    <dialog-body>
      <div class="flex w-full max-w-[800px] flex-col space-y-5 py-1">
        <div class="flex w-full flex-row items-start space-x-3 pb-1.5">
          <table class="table w-full">
            <thead>
              <tr class="text-sm uppercase opacity-50">
                <td>Time</td>
                <td>Before</td>
                <td>After</td>
                <td>Change</td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="change in props.campaign.budget_changes"
                :key="change.id"
              >
                <td>
                  {{
                    dayjs.unix(change.time / 1000).format("h:mma, Do MMM YYYY")
                  }}
                </td>
                <td>
                  {{
                    currencyStore.format(
                      change.effective_daily_budget_before,
                      props.currency,
                    )
                  }}
                </td>
                <td>
                  {{
                    currencyStore.format(
                      change.effective_daily_budget_after,
                      props.currency,
                    )
                  }}
                </td>
                <td>
                  <span
                    v-if="
                      change.effective_daily_budget_after -
                        change.effective_daily_budget_before >
                      0
                    "
                    class="text-emerald-600"
                    >{{
                      currencyStore.format(
                        change.effective_daily_budget_after -
                          change.effective_daily_budget_before,
                        props.currency,
                      )
                    }}</span
                  >
                  <span
                    v-if="
                      change.effective_daily_budget_after -
                        change.effective_daily_budget_before <
                      0
                    "
                    class="text-rose-700"
                    >{{
                      currencyStore.format(
                        change.effective_daily_budget_after -
                          change.effective_daily_budget_before,
                        props.currency,
                      )
                    }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </dialog-body>
  </div>
</template>
