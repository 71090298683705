<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      The target metric you select for each shared budget is the metric
      Charter’s algorithm will optimise for across the campaigns within that
      shared budget.
    </p>
    <p>[screenshot of this]</p>
    <p>You can select from the following target metrics:</p>
    <p>Revenue e.g. for sales campaigns</p>
    <p>Impressions e.g. for brand awareness campaigns</p>
    <p>Reach e.g. for brand awareness campaigns</p>
    <p>
      Clicks e.g. for trying to catch as much of your audience’s attention as
      possible
    </p>
    <p>Unique clicks e.g. for trying to reach new audiences</p>
    <p>Return on ad spend e.g. to maximise the efficiency of your ads</p>
    <p>
      Cost per purchase e.g. similar to ROAS for efficiency but better for
      products with a narrower price range
    </p>
    <p>
      Average purchase value e.g. to maximise campaigns which result in high
      order values
    </p>
  </div>
</template>
