<script setup lang="ts"></script>
<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.1999 25.0574V20.1599C43.1999 19.2974 42.6224 18.5287 41.8574 18.3374L37.4399 16.9912C32.3512 15.4574 27.5512 14.1112 22.4624 12.4799H22.3687C21.5999 12.2887 20.8312 13.1512 21.2174 13.9199C23.0399 17.6624 24.9599 23.0399 24.9599 23.0399L31.3912 25.5374C31.1024 25.5374 10.5599 36.4799 10.5599 36.4799L19.1999 28.7999V6.71992C19.1999 5.85742 18.6224 4.99117 17.8574 4.79992C17.8574 4.79992 13.1512 2.97742 10.1774 2.01742C9.98243 1.91992 9.79118 1.91992 9.59993 1.91992C9.21743 1.91992 8.83118 2.01742 8.44868 2.30242C7.96868 2.68867 7.67993 3.26242 7.67993 3.83992V37.1512C7.67993 37.8224 7.96868 38.3999 8.54243 38.6887C10.5599 40.1287 12.6712 41.5687 14.6887 43.0087L17.5687 45.0224C17.8574 45.2174 18.2399 45.4087 18.6224 45.4087C19.0087 45.4087 19.2974 45.3112 19.5824 45.1199C23.7112 42.6224 27.9374 40.1287 32.0624 37.6312L42.2399 31.5824C42.8174 31.1999 43.1999 30.6224 43.1999 29.9512V25.0574Z"
      fill="#008272"
    />
  </svg>
</template>
