<script setup lang="ts">
import { nextTick } from "vue";

import InputText from "@/components/form/controls/input-text.vue";

const emit = defineEmits<{
  (e: "input", event: KeyboardEvent): void;
}>();

const props = defineProps({
  dp: {
    type: Number,
    required: false,
    default: 2,
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const amount = defineModel<string>();

const formatNumber = (value) => {
  const parts = value.split(".");
  const integerPart = parts[0];
  const decimalPart = parts.length >= 2 ? `${"."}${parts[1]}` : "";
  return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + decimalPart;
};

const onInput = (event) => {
  const inputElement = event.target;
  let value = inputElement.value.replace(new RegExp(",", "g"), ""); // Remove commas to manipulate raw number

  // Save the initial caret position
  const originalCaretPosition = inputElement.selectionStart;

  // Get the number of commas before formatting
  const originalCommasBefore = (
    inputElement.value.match(new RegExp(",", "g")) || []
  ).length;

  // Handle decimal places
  if (value.includes(".")) {
    const [integerPart, decimalPart] = value.split(".");
    if (decimalPart.length > props.dp) {
      value = `${integerPart}.${decimalPart.slice(0, props.dp)}`;
    }
  }
  // Format the number with commas
  const formattedValue = formatNumber(value);
  amount.value = formattedValue;

  // Get the number of commas after formatting
  const newCommasAfter = (formattedValue.match(new RegExp(",", "g")) || [])
    .length;

  // Calculate the caret adjustment based on added/removed commas
  const commaAdjustment = newCommasAfter - originalCommasBefore;
  const newCaretPosition = originalCaretPosition + commaAdjustment;

  // Restore the caret position
  nextTick(() => {
    inputElement.value = amount.value;
    inputElement.setSelectionRange(newCaretPosition, newCaretPosition);
  });

  emit("input", event);
};
const isNumberKey = (event: any) => {
  const charCode = event.which ? event.which : event.keyCode;

  // Allow control keys (backspace, delete, etc.)
  if (charCode < 32) return true;

  // Allow digits 0-9
  if (charCode >= 48 && charCode <= 57) return true;

  // Allow a single decimal point if it hasn't already been added
  if (amount.value && charCode === 46 && !amount.value.includes(".")) {
    return true;
  }

  // Prevent any other characters
  event.preventDefault();
  return false;
};
</script>
<template>
  <input-text
    :readonly="props.readonly"
    :disabled="props.disabled"
    v-model="amount"
    @input="onInput"
    :class="`input ${props.disabled ? `disabled` : ``}`"
    @keypress="isNumberKey"
  />
</template>
