<script setup lang="ts">
import { Crosshair2Icon } from "@radix-icons/vue";
import { PropType } from "vue";

import Badge from "@/components/badge.vue";
import { SharedBudget } from "@/dtos/shared-budget.ts";
import { BadgeState } from "@/enums/badge-state.ts";
import { TargetMetric } from "@/enums/target-metric.ts";
import { formatNumber } from "@/helpers/formatting.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const currencyStore = useCurrencyStore();
const workspaceStore = useWorkspaceStore();

const props = defineProps({
  group: {
    type: Object as PropType<SharedBudget>,
    required: true,
  },
});
</script>
<template>
  <template v-if="workspaceStore.workspace">
    <template v-if="props.group.target_metric === TargetMetric.AD_PROFIT">
      <badge
        v-if="props.group.daily_ad_profit_forecast !== undefined"
        :state="
          props.group.daily_ad_profit_forecast > 0
            ? BadgeState.POSITIVE
            : BadgeState.NEGATIVE
        "
        class="ad-profit ml-3 mr-3"
      >
        <div class="flex items-center justify-center space-x-2 font-mono">
          <span class="font-medium"
            >{{
              currencyStore.format(
                (props.group.daily_ad_profit_forecast * 365.25) / 12,
                workspaceStore.workspace.currency,
              )
            }} </span
          ><span class="inline">monthly ad profit</span>
        </div>
      </badge>
      <div
        v-else
        class="flex items-center space-x-3 font-mono text-sm opacity-50"
      >
        <Crosshair2Icon class="h-4 w-4"></Crosshair2Icon>
        <span>Ad profit</span>
      </div>
    </template>

    <template v-if="props.group.target_metric === TargetMetric.REVENUE">
      <badge
        v-if="props.group.daily_revenue_forecast !== undefined"
        :state="
          props.group.daily_revenue_forecast > 0
            ? BadgeState.POSITIVE
            : BadgeState.NEGATIVE
        "
        class="profit ml-3 mr-3"
      >
        <div class="flex items-center justify-center space-x-2 font-mono">
          <span class="font-medium"
            >{{
              currencyStore.format(
                (props.group.daily_revenue_forecast * 365.25) / 12,
                workspaceStore.workspace.currency,
              )
            }} </span
          ><span class="inline">monthly revenue</span>
        </div>
      </badge>
      <div
        v-else
        class="flex items-center space-x-3 font-mono text-sm opacity-50"
      >
        <Crosshair2Icon class="h-4 w-4"></Crosshair2Icon>
        <span>Revenue</span>
      </div>
    </template>
    <template v-if="props.group.target_metric === TargetMetric.IMPRESSIONS">
      <badge
        v-if="props.group.daily_impressions_forecast !== undefined"
        :state="
          props.group.daily_impressions_forecast > 0
            ? BadgeState.POSITIVE
            : BadgeState.NEGATIVE
        "
        class="profit ml-3 mr-3"
      >
        <div class="flex items-center justify-center space-x-2 font-mono">
          <span class="font-medium"
            >{{
              formatNumber(
                (props.group.daily_impressions_forecast * 365.25) / 12,
                0,
              )
            }} </span
          ><span class="inline">monthly impressions</span>
        </div>
      </badge>
      <div
        v-else
        class="flex items-center space-x-3 font-mono text-sm opacity-50"
      >
        <Crosshair2Icon class="h-4 w-4"></Crosshair2Icon>
        <span>Impressions</span>
      </div>
    </template>
    <template v-if="props.group.target_metric === TargetMetric.REACH">
      <badge
        v-if="props.group.daily_reach_forecast !== undefined"
        :state="
          props.group.daily_reach_forecast > 0
            ? BadgeState.POSITIVE
            : BadgeState.NEGATIVE
        "
        class="profit ml-3 mr-3"
      >
        <div class="flex items-center justify-center space-x-2 font-mono">
          <span class="font-medium"
            >{{
              formatNumber((props.group.daily_reach_forecast * 365.25) / 12, 0)
            }} </span
          ><span class="inline">monthly reach</span>
        </div>
      </badge>
      <div
        v-else
        class="flex items-center space-x-3 font-mono text-sm opacity-50"
      >
        <Crosshair2Icon class="h-4 w-4"></Crosshair2Icon>
        <span>Reach</span>
      </div>
    </template>
    <template v-if="props.group.target_metric === TargetMetric.CLICKS">
      <badge
        v-if="props.group.daily_clicks_forecast !== undefined"
        :state="
          props.group.daily_clicks_forecast > 0
            ? BadgeState.POSITIVE
            : BadgeState.NEGATIVE
        "
        class="profit ml-3 mr-3"
      >
        <div class="flex items-center justify-center space-x-2 font-mono">
          <span class="font-medium"
            >{{
              formatNumber((props.group.daily_clicks_forecast * 365.25) / 12, 0)
            }} </span
          ><span class="inline">monthly clicks</span>
        </div>
      </badge>
      <div
        v-else
        class="flex items-center space-x-3 font-mono text-sm opacity-50"
      >
        <Crosshair2Icon class="h-4 w-4"></Crosshair2Icon>
        <span>Clicks</span>
      </div>
    </template>
    <template v-if="props.group.target_metric === TargetMetric.UNIQUE_CLICKS">
      <badge
        v-if="props.group.daily_unique_clicks_forecast !== undefined"
        :state="
          props.group.daily_unique_clicks_forecast > 0
            ? BadgeState.POSITIVE
            : BadgeState.NEGATIVE
        "
        class="profit ml-3 mr-3"
      >
        <div class="flex items-center justify-center space-x-2 font-mono">
          <span class="font-medium"
            >{{
              formatNumber(
                (props.group.daily_unique_clicks_forecast * 365.25) / 12,
                0,
              )
            }} </span
          ><span class="inline">monthly unique clicks</span>
        </div>
      </badge>
      <div
        v-else
        class="flex items-center space-x-3 font-mono text-sm opacity-50"
      >
        <Crosshair2Icon class="h-4 w-4"></Crosshair2Icon>
        <span>Unique clicks</span>
      </div>
    </template>
    <template v-if="props.group.target_metric === TargetMetric.ROAS">
      <badge
        v-if="props.group.roas_in_previous_week !== undefined"
        :state="
          props.group.roas_in_previous_week > 1
            ? BadgeState.POSITIVE
            : BadgeState.NEGATIVE
        "
        class="profit ml-3 mr-3"
      >
        <div class="flex items-center justify-center space-x-2 font-mono">
          <span class="font-medium"
            >{{ formatNumber(props.group.roas_in_previous_week, 2) }} </span
          ><span class="inline">ROAS</span>
        </div>
      </badge>
      <div
        v-else
        class="flex items-center space-x-3 font-mono text-sm opacity-50"
      >
        <Crosshair2Icon class="h-4 w-4"></Crosshair2Icon>
        <span>ROAS</span>
      </div>
    </template>
    <template
      v-if="props.group.target_metric === TargetMetric.COST_PER_PURCHASE"
    >
      <badge
        v-if="props.group.daily_cpp_forecast !== undefined"
        :state="BadgeState.POSITIVE"
        class="profit ml-3 mr-3"
      >
        <div class="flex items-center justify-center space-x-2 font-mono">
          <span class="font-medium"
            >{{
              currencyStore.format(
                props.group.daily_cpp_forecast,
                workspaceStore.workspace.currency,
              )
            }} </span
          ><span class="inline">cost per conversion</span>
        </div>
      </badge>
      <div
        v-else
        class="flex items-center space-x-3 font-mono text-sm opacity-50"
      >
        <Crosshair2Icon class="h-4 w-4"></Crosshair2Icon>
        <span>Cost per conversion</span>
      </div>
    </template>
    <template
      v-if="
        props.group.target_metric === TargetMetric.AVERAGE_CONVERSION_REVENUE
      "
    >
      <badge
        v-if="props.group.daily_acr_forecast !== undefined"
        :state="BadgeState.POSITIVE"
        class="profit ml-3 mr-3"
      >
        <div class="flex items-center justify-center space-x-2 font-mono">
          <span class="font-medium"
            >{{
              currencyStore.format(
                props.group.daily_acr_forecast,
                workspaceStore.workspace.currency,
              )
            }} </span
          ><span class="inline">avg conversion revenue</span>
        </div>
      </badge>
      <div
        v-else
        class="flex items-center space-x-3 font-mono text-sm opacity-50"
      >
        <Crosshair2Icon class="h-4 w-4"></Crosshair2Icon>
        <span>Avg conversion revenue</span>
      </div>
    </template>
  </template>
</template>
