<script setup lang="ts">
import { watchOnce } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";

import BlockGrid from "@/app/workspace/campaigns/block-grid.vue";
import CampaignBlock from "@/app/workspace/campaigns/blocks/block.vue";
import GroupTitle from "@/app/workspace/campaigns/shared-budgets/shared-budget-title.vue";
import TimeframeSelect from "@/app/workspace/campaigns/timeframe-select.vue";
import Tabs from "@/app/workspace/tabs.vue";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();
const route = useRoute();
const router = useRouter();

const loaded = () => {
  if (!workspaceStore.workspace?.ad_channels.length) {
    router.replace({
      name: "ad-channels",
      params: {
        params: route.params.workspace,
      },
      query: {
        setup: "true",
      },
    });
    return;
    // } else if (!workspaceStore.workspace?.storefronts.length) {
    //   router.replace({
    //     name: "storefronts",
    //     params: {
    //       params: route.params.workspace,
    //     },
    //     query: {
    //       setup: "true",
    //     },
    //   });
    //   return;
  }
};

if (workspaceStore.workspace) {
  loaded();
} else {
  watchOnce(() => workspaceStore.workspace, loaded);
}
</script>
<template>
  <router-view v-if="route.name !== 'campaigns'"></router-view>
  <div class="w-full" v-else>
    <tabs class="mb-7 mt-0">
      <div
        class="relative top-1 flex select-none items-center space-x-12 text-base text-zinc-500"
      >
        <timeframe-select></timeframe-select>
      </div>
    </tabs>
    <div
      class="flex flex-col space-y-8"
      v-if="
        workspaceStore.groupedCampaigns &&
        workspaceStore.groupedCampaigns.length
      "
    >
      <div
        class="pb-7"
        v-for="group_of_campaigns in workspaceStore.groupedCampaigns"
        :key="group_of_campaigns.key"
      >
        <group-title
          :group="group_of_campaigns.group"
          v-if="
            workspaceStore.groupedCampaigns.length >= 2 ||
            !!group_of_campaigns.group
          "
        ></group-title>
        <block-grid v-if="group_of_campaigns.campaigns.length">
          <campaign-block
            v-for="campaign in group_of_campaigns.campaigns"
            :key="campaign.id"
            :campaign="campaign"
            :group="group_of_campaigns.group"
          >
          </campaign-block>
        </block-grid>
        <div class="px-9" v-else>
          <span class="opacity-20">(Empty)</span>
        </div>
      </div>
    </div>
    <div class="h-16"></div>
  </div>
</template>
