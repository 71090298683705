<script setup lang="ts">
const props = defineProps({
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const selected = defineModel<any>();
</script>
<template>
  <select
    v-model="selected"
    :style="props.readonly ? `pointer-events: none;` : ``"
  >
    <slot></slot>
  </select>
</template>
