<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      Shared budgets in Charter are how you group your ad campaigns according to
      different metrics you may be targeting.
    </p>
    <p>
      For example, you may be running a group of ads across Meta and Google with
      the target metric of Reach to create more brand awareness, with a shared
      budget of $2,000/month.
    </p>
    <p>To learn more about share budgets click here.</p>
    <p>[Screenshot - create shared budget button]</p>
    <p>
      To create a shared budget, click the “create shared budget” button in the
      top right of your dashboard. Input your chosen shared budget amount,
      select your target metric, and optionally give the shared budget a name
      e.g. “Brand awareness”. Then click “create shared budget”.
    </p>
    <p>
      Within this shared budget dialog, you can also set Thresholds [link to
      Thresholds concept doc] and adjust Approval [link to Approval concept doc]
      settings. Click here to learn more about these concepts [link to
      Concepts].
    </p>
    <p>
      To move campaigns into this shared budget, click the ∨ button in the top
      right of the campaign box. Then click “budget > assign” and select the
      shared budget you’d like to move the campaign into. [screenshot]
    </p>
  </div>
</template>
