import { RouteLocationRaw } from "vue-router";

export enum AuthContext {
  SIGN_IN = "sign-in",
  SIGN_UP = "sign-up",
}

export const authContextLabel = (context: AuthContext) => {
  if (context === AuthContext.SIGN_IN) {
    return "Sign in";
  } else if (context === AuthContext.SIGN_UP) {
    return "Sign up";
  }
  throw new Error("Unknown auth context: " + context);
};

export const authContextRoute = (context: AuthContext): RouteLocationRaw => {
  if (context === AuthContext.SIGN_IN) {
    return {
      name: "sign-in",
    };
  } else if (context === AuthContext.SIGN_UP) {
    return {
      name: "sign-up",
    };
  }
  throw new Error("Unknown auth context: " + context);
};
