<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";
import team_management from "@/assets/docs/team-management.png";
const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      You can manage team access per workspace through the workspace settings.
    </p>
    <div class="rounded-md border border-white/30">
      <img class="object-cover" :src="team_management" />
    </div>
    <p>There are 4 different roles in Charter:</p>
    <ul>
      <li>Admin</li>
      <ul>
        <li>Full access to the account</li>
        <li>Automatically added to all new workspaces</li>
        <li>Can add/remove team members (all roles)</li>
        <li>Responsible for billing</li>
      </ul>
    </ul>
    <ul>
      <li>Editor (account-wide)</li>
      <ul>
        <li>Full access to the account</li>
        <li>Automatically added to all new workspaces</li>
        <li>Can add/remove read-only members</li>
      </ul>
    </ul>
    <ul>
      <li>Editor (workspaces)</li>
      <ul>
        <li>Access only to the workspaces they are invited to</li>
        <li>Can add/remove read-only members</li>
      </ul>
    </ul>
    <ul>
      <li>Read-only</li>
      <ul>
        <li>Access to view the workspaces they are invited to</li>
      </ul>
    </ul>
    <p>
      For example, you own a marketing agency so you are the admin of your
      account. You invite a colleague as an editor to work on a client’s ads,
      and your colleague invites the client as a read-only viewer so they can
      see how their ads are progressing.
    </p>
  </div>
</template>
