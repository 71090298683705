<script setup lang="ts">
import { watchOnce } from "@vueuse/core";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import LoadingBar from "@/components/loading-bar.vue";
import { AdChannel } from "@/dtos/ad-channel.ts";
import { MetaAdAccountOption } from "@/dtos/meta-ad-account-option.ts";
import { Workspace } from "@/dtos/workspace.ts";
import { api } from "@/helpers/api.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const route = useRoute();
const router = useRouter();
const workspaceStore = useWorkspaceStore();

type TokenAndAdChannelResponse = {
  access_token: string;
  ad_channels: MetaAdAccountOption[];
};

const state = route.query.state as string;
const [context, workspace_id] = state.split("|", 2);

if (route.query.error) {
  router.replace({
    name: "ad-channels",
    params: {
      workspace: parseInt(workspace_id),
    },
  });
}

const loaded = async () => {
  if (route.query.error) {
    return;
  }
  const code = route.query.code;

  const data = (await api.get(
    `${import.meta.env.VITE_API_V2_ENABLED === "true" ? `meta/ad-channel/options` : `meta/ad-account-options`}?code=${code}&redirect_uri=${window.location.origin}/oauth/meta-ad-channel`,
  )) as TokenAndAdChannelResponse;

  if (data.ad_channels.length === 0) {
    alert("No ad account found");
    await router.replace({
      name: "ad-channels",
      params: {
        workspace: parseInt(workspace_id),
      },
      query: {
        setup: context === "setup" ? "true" : undefined,
      },
    });
  } else if (data.ad_channels.length >= 2) {
    await router.replace({
      name: "ad-channels",
      params: {
        workspace: parseInt(workspace_id),
      },
      query: {
        setup: context === "setup" ? "true" : undefined,
        option: "meta",
        access_token: data.access_token,
        ad_channels: JSON.stringify(data.ad_channels),
      },
    });
  } else {
    const workspace = workspaceStore.workspaces!.find(
      (workspace: Workspace) => workspace.id === parseInt(workspace_id),
    );
    const ad_channel_data = await api.put(
      import.meta.env.VITE_API_V2_ENABLED === "true"
        ? `meta/ad-channel/create`
        : `meta/ad-channel`,
      {
        workspace_id: workspaceStore.workspace!.id,
        access_token: data.access_token,
        ad_channel_id: data.ad_channels[0].id,
      },
    );
    workspaceStore.mergeAdChannel(ad_channel_data as AdChannel, workspace!);

    await router.replace({
      name: "ad-channels",
      params: {
        workspace: workspace_id,
      },
      query: {
        setup: context === "setup" ? "true" : undefined,
        done: "true",
      },
    });
  }
};
onMounted(() => {
  if (workspaceStore.workspaces !== undefined) {
    loaded();
  } else {
    watchOnce(() => workspaceStore.workspaces, loaded);
  }
});
</script>
<template>
  <div class="flex grow items-center justify-center">
    <loading-bar></loading-bar>
  </div>
</template>
