import { Capacitor } from "@capacitor/core";
import { defineStore } from "pinia";
import { ref } from "vue";
import { RouteLocationNormalized } from "vue-router";

import {
  SerializableMatch,
  SerializableRoute,
} from "@/dtos/serializable-route.ts";

export const useProfileStore = defineStore("profile", () => {
  // previous route

  const previousRoute = ref<SerializableRoute | undefined>(
    Capacitor.isNativePlatform()
      ? undefined
      : sessionStorage.getItem("profile-previous-route")
        ? (JSON.parse(
            sessionStorage.getItem("profile-previous-route") as string,
          ) as SerializableRoute)
        : undefined,
  );
  const setPreviousRoute = (route: RouteLocationNormalized) => {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    const raw = {
      name: route.name,
      params: route.params,
      query: route.query,
      hash: route.hash,
      meta: route.meta,
      matched: route.matched.map((item) => {
        return {
          name: item.name,
          meta: item.meta,
        } as SerializableMatch;
      }),
    } as SerializableRoute;
    previousRoute.value = raw;
    sessionStorage.setItem("profile-previous-route", JSON.stringify(raw));
  };
  const clearPreviousRoute = () => {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    previousRoute.value = undefined;
    sessionStorage.removeItem("profile-previous-route");
  };

  return {
    // previous route
    previousRoute,
    setPreviousRoute,
    clearPreviousRoute,
  };
});
