<script setup lang="ts">
import { ArrowLeftIcon } from "@radix-icons/vue";
import { watchOnce } from "@vueuse/core";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import PageLayout from "@/app/page-layout.vue";
import Block from "@/app/workspace/approval/block.vue";
import StyledButton from "@/components/form/styled-button.vue";
import { BudgetingAlgorithmRun } from "@/dtos/budgeting-algorithm-run.ts";
import { BudgetingAlgorithmRunStatus } from "@/enums/budgeting-algorithm-run-status.ts";
import { ButtonSize } from "@/enums/button-size.ts";
import { ButtonType } from "@/enums/button-type.ts";
import { api } from "@/helpers/api.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();
const route = useRoute();
const router = useRouter();

const pending_budgeting_algorithm_runs = computed<
  BudgetingAlgorithmRun[] | undefined
>(() => {
  if (!workspaceStore.workspace) {
    return undefined;
  }
  const items: BudgetingAlgorithmRun[] = [];
  for (let i = 0; i < workspaceStore.workspace.shared_budgets.length; i++) {
    for (
      let ii = 0;
      ii < workspaceStore.workspace.shared_budgets[i].runs.length;
      ii++
    ) {
      if (
        workspaceStore.workspace.shared_budgets[i].runs[ii].status ===
        BudgetingAlgorithmRunStatus.PENDING
      ) {
        items.push(workspaceStore.workspace.shared_budgets[i].runs[ii]);
        break;
      }
    }
  }
  return items;
});

const redirectIfComplete = () => {
  if (pending_budgeting_algorithm_runs.value?.length === 0) {
    router.push({
      name: "campaigns",
      params: { workspace: route.params.workspace },
    });
  }
};
const loaded = () => {
  redirectIfComplete();
};

if (workspaceStore.workspace) {
  loaded();
} else {
  watchOnce(() => workspaceStore.workspace, loaded);
}

const approveAll = () => {
  if (workspaceStore.workspace == undefined) {
    return;
  }
  for (let i = 0; i < workspaceStore.workspace.shared_budgets.length; i++) {
    workspaceStore.workspace.shared_budgets[i].runs = [];
  }
  api.put(
    `workspace/shared-budget/approve-all?workspace_id=${workspaceStore.workspace.id}`,
  );
  redirectIfComplete();
};
</script>
<template>
  <page-layout v-if="workspaceStore.workspace" :tabs="false" :centered="false">
    <div class="w-full px-7">
      <div class="mb-5 flex items-center">
        <router-link
          class="flex items-center space-x-2 text-lg text-white/30 transition-all duration-75 hover:text-white"
          :to="{
            name: 'campaigns',
            params: { workspace: route.params.workspace },
          }"
          ><ArrowLeftIcon class="h-5 w-auto"></ArrowLeftIcon>
          <span>Dashboard</span></router-link
        >
      </div>
      <div class="flex flex-col items-center space-y-9">
        <div
          class="flex w-full max-w-[800px] flex-col items-center space-y-7 pb-20"
        >
          <div class="flex w-full items-center justify-between px-[10px]">
            <span class="relative -top-[2px] text-2xl font-medium"
              >Pending budget changes</span
            ><styled-button
              :type="ButtonType.PRIMARY"
              :size="ButtonSize.MEDIUM"
              @click="approveAll"
            >
              Approve all
            </styled-button>
          </div>
          <div class="flex w-full flex-col space-y-6">
            <block
              v-for="run in pending_budgeting_algorithm_runs"
              :key="run.id"
              :run="run"
              @approved="redirectIfComplete"
              @rejected="redirectIfComplete"
            ></block>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>
