<script setup lang="ts"></script>
<template>
  <div class="flex grow items-center justify-center px-10 pb-52">
    <span
      class="mx-auto block grow text-balance text-center text-[50px] font-medium leading-[78px]"
      ><span class="z-10">Paid ads, </span>
      <span class="relative inline-block font-semibold">
        <span>simplified</span>
        <span
          class="absolute bottom-[7px] left-[3px] z-[0] inline-block h-[3px] w-[calc(100%-5px)] rounded-full bg-gradient-to-r from-magic-green to-magic-purple sm:bottom-[5px] sm:h-[7px]"
        ></span> </span
    ></span>
  </div>
</template>
