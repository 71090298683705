import { defineStore } from "pinia";
import { ref } from "vue";

import { Country, CountryRaw } from "@/dtos/country.ts";
import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useLanguageStore } from "@/stores/language.ts";

export const useCountryStore = defineStore("country", () => {
  const countries = ref<Country[] | undefined>(undefined);

  const currencyStore = useCurrencyStore();
  const languageStore = useLanguageStore();

  const load = (countries_: CountryRaw[]) => {
    countries.value = countries_.map((country_raw: CountryRaw) => {
      return nullsToUndefined({
        ...country_raw,
        currency: currencyStore.find(country_raw.currency_id),
        language: languageStore.find(country_raw.language_id),
      }) as Country;
    });
  };
  const clear = () => {
    countries.value = undefined;
  };

  const find = (country_id: number): Country | undefined => {
    return countries.value!.find(
      (country: Country) => country.id === country_id,
    );
  };

  return {
    countries,
    load,
    find,
    clear,
  };
});
