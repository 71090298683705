<script setup lang="ts">
import { nextTick, PropType } from "vue";

import InputText from "@/components/form/controls/input-text.vue";
import { Currency } from "@/dtos/currency.ts";
import { CurrencySymbolPosition } from "@/enums/currency-symbol-position.ts";

const emit = defineEmits<{
  (e: "input", event: KeyboardEvent): void;
}>();

const props = defineProps({
  currency: {
    type: Object as PropType<Currency>,
    required: true,
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const amount = defineModel<string>();

const formatNumber = (value) => {
  const parts = value.split(props.currency.decimal_separator);
  const integerPart = parts[0];
  const decimalPart =
    parts.length >= 2 ? `${props.currency.decimal_separator}${parts[1]}` : "";
  return (
    integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      props.currency.thousands_separator,
    ) + decimalPart
  );
};

const onInput = (event) => {
  const inputElement = event.target;
  let value = inputElement.value.replace(
    new RegExp(props.currency.thousands_separator, "g"),
    "",
  ); // Remove commas to manipulate raw number

  // Save the initial caret position
  const originalCaretPosition = inputElement.selectionStart;

  // Get the number of commas before formatting
  const originalCommasBefore = (
    inputElement.value.match(
      new RegExp(props.currency.thousands_separator, "g"),
    ) || []
  ).length;

  // Handle decimal places
  if (value.includes(props.currency.decimal_separator)) {
    const [integerPart, decimalPart] = value.split(
      props.currency.decimal_separator,
    );
    if (decimalPart.length > 2) {
      value = `${integerPart}.${decimalPart.slice(0, 2)}`;
    }
  }
  // Format the number with commas
  const formattedValue = formatNumber(value);
  amount.value = formattedValue;

  // Get the number of commas after formatting
  const newCommasAfter = (
    formattedValue.match(new RegExp(props.currency.thousands_separator, "g")) ||
    []
  ).length;

  // Calculate the caret adjustment based on added/removed commas
  const commaAdjustment = newCommasAfter - originalCommasBefore;
  const newCaretPosition = originalCaretPosition + commaAdjustment;

  // Restore the caret position
  nextTick(() => {
    inputElement.value = amount.value;
    inputElement.setSelectionRange(newCaretPosition, newCaretPosition);
  });

  emit("input", event);
};
const isNumberKey = (event: any) => {
  const charCode = event.which ? event.which : event.keyCode;

  // Allow control keys (backspace, delete, etc.)
  if (charCode < 32) return true;

  // Allow digits 0-9
  if (charCode >= 48 && charCode <= 57) return true;

  // Allow a single decimal point if it hasn't already been added
  if (
    amount.value &&
    charCode === 46 &&
    !amount.value.includes(props.currency.decimal_separator)
  ) {
    return true;
  }

  // Prevent any other characters
  event.preventDefault();
  return false;
};
</script>
<template>
  <div :class="`relative flex w-full items-center`">
    <span
      v-if="props.currency.symbol_position === CurrencySymbolPosition.LEFT"
      class="absolute left-[14px] text-white/50"
      >{{ props.currency.symbol }}</span
    >
    <input-text
      :readonly="props.readonly"
      :disabled="props.disabled"
      v-model="amount"
      class="indent-[22px]"
      @input="onInput"
      @keypress="isNumberKey"
    />
    <span
      v-if="props.currency.symbol_position === CurrencySymbolPosition.RIGHT"
      class="absolute right-[14px] text-white/50"
      >{{ props.currency.symbol }}</span
    >
  </div>
</template>
