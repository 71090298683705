<script setup lang="ts"></script>
<template>
  <div class="flex w-full grow flex-col space-y-2.5">
    <div class="relative flex items-center justify-between">
      <slot name="label" />
    </div>
    <slot name="description" />
    <slot name="control" />
    <slot name="error" />
  </div>
</template>
