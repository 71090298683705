<script setup lang="ts"></script>
<template>
  <svg
    class="fill-current"
    width="27"
    height="23"
    viewBox="0 0 27 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.03846 0C0.46494 0 0 0.572087 0 1.27778C0 1.98347 0.46494 2.55556 1.03846 2.55556H25.9615C26.535 2.55556 27 1.98347 27 1.27778C27 0.572087 26.535 0 25.9615 0H1.03846ZM0 11.5C0 10.7943 0.46494 10.2222 1.03846 10.2222H25.9615C26.535 10.2222 27 10.7943 27 11.5C27 12.2057 26.535 12.7778 25.9615 12.7778H1.03846C0.46494 12.7778 0 12.2057 0 11.5ZM0 21.7222C0 21.0166 0.46494 20.4444 1.03846 20.4444H25.9615C26.535 20.4444 27 21.0166 27 21.7222C27 22.4278 26.535 23 25.9615 23H1.03846C0.46494 23 0 22.4278 0 21.7222Z"
    />
  </svg>
</template>
