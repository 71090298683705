import { Preferences } from "@capacitor/preferences";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { AuthenticationSuccess } from "@/dtos/authentication-result.ts";
import { GuestInit } from "@/dtos/guest-init.ts";
import { User, UserRaw } from "@/dtos/user.ts";
import { UserInit } from "@/dtos/user-init.ts";
import { api } from "@/helpers/api.ts";
import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";
import { useAccountStore } from "@/stores/account.ts";
import { useCountryStore } from "@/stores/country.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useLanguageStore } from "@/stores/language.ts";
import { useProfileStore } from "@/stores/profile.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";
// import { showConnectionErrorModal, showServerErrorModal } from '@/functions/errors'

export const useAuthStore = defineStore("auth", () => {
  const router = useRouter();
  const route = useRoute();
  const workspaceStore = useWorkspaceStore();
  const languageStore = useLanguageStore();
  const accountStore = useAccountStore();
  const profileStore = useProfileStore();
  const currencyStore = useCurrencyStore();
  const countryStore = useCountryStore();

  // stored token

  const user = ref<User | undefined>(undefined);
  const initialised = ref<boolean>(false);
  const whenInitialised = computed(() => initialised);
  const token = ref<string | undefined>(window.token);
  delete window.token;

  watch(token, () => {
    if (token.value) {
      Preferences.set({
        key: "token",
        value: `${token.value}`,
      }).then(() => {});
    } else {
      Preferences.remove({ key: "token" }).then(() => {});
    }
  });

  watch(user, () => {
    if (user.value) {
      Preferences.set({
        key: "user",
        value: JSON.stringify(user.value),
      }).then(() => {});
    } else {
      Preferences.remove({ key: "user" }).then(() => {});
    }
  });

  // user data

  const convertRawUser = (user_raw: UserRaw): User => {
    return nullsToUndefined({
      ...user_raw,
      language: languageStore.find(user_raw.language_id),
      country: countryStore.find(user_raw.country_id),
    }) as User;
  };

  const load = (data: UserInit) => {
    currencyStore.load(data.currencies);
    languageStore.load(data.languages);
    countryStore.load(data.countries);
    user.value = convertRawUser(data.user);
    accountStore.setAccounts(data.accounts);
    workspaceStore.setWorkspaces(data.workspaces);
    initialised.value = true;
  };
  const loadGuest = (data: GuestInit) => {
    currencyStore.load(data.currencies);
    languageStore.load(data.languages);
    countryStore.load(data.countries);
    initialised.value = true;
  };
  const clear = () => {
    token.value = undefined;
    user.value = undefined;
    workspaceStore.clearWorkspaces();
    accountStore.clearAccounts();
    workspaceStore.clearRecentRoute();
    accountStore.clearPreviousRoute();
    profileStore.clearPreviousRoute();
    initialised.value = false;
  };
  const initialise = () => {
    if (token.value) {
      api
        .get(
          `${import.meta.env.VITE_API_V2_ENABLED === "true" ? `init/authenticated` : `auth/init/user`}?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
        )
        .then((data: unknown) => {
          load(data as UserInit);
        })
        .catch(() => {});
    } else {
      api
        .get(
          `${import.meta.env.VITE_API_V2_ENABLED === "true" ? `init/unauthenticated` : `auth/init/guest`}?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
        )
        .then((data: unknown) => {
          loadGuest(data as GuestInit);
        });
    }
  };

  initialise();

  // authentication

  const signIn = async (
    authentication_success_response: AuthenticationSuccess,
  ) => {
    load(authentication_success_response.init);
    if (authentication_success_response.token) {
      token.value = authentication_success_response.token;
    }
  };
  const signOut = async () => {
    clear();
    initialise();
    if (
      route.name !== "sign-in" &&
      route.matched[0].name !== "docs" &&
      route.name !== "home"
    ) {
      await router.push({ name: "home" });
    }
    if (import.meta.env.VITE_API_V2_ENABLED === "true") {
      api
        .delete(`auth/close`)
        .then(() => {})
        .catch(() => {});
    }
  };

  return {
    initialised,
    initialise,
    whenInitialised,
    user,
    convertRawUser,
    signIn,
    signOut,
    // userChanged,
    load,
    token,
  };
});
