import { defineStore } from "pinia";
import { ref } from "vue";

import { Language } from "@/dtos/language.ts";
import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";

export const useLanguageStore = defineStore("language", () => {
  const languages = ref<Language[] | undefined>(undefined);

  const load = (languages_: Language[]) => {
    languages.value = nullsToUndefined(languages_);
  };
  const clear = () => {
    languages.value = undefined;
  };

  const find = (language_id: number): Language | undefined => {
    return languages.value!.find(
      (language: Language) => language.id === language_id,
    );
  };

  return {
    languages,
    load,
    find,
    clear,
  };
});
