<script setup lang="ts">
const emit = defineEmits<{
  (e: "input", event: KeyboardEvent): void;
}>();

const props = defineProps({
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const value = defineModel<string>();

const onInput = (event) => {
  emit("input", event);
};
</script>
<template>
  <input
    :readonly="props.readonly"
    v-model="value"
    @input="onInput"
    :class="`input ${props.disabled ? `disabled` : ``}`"
  />
</template>
