<script setup lang="ts">
import { Cross1Icon } from "@radix-icons/vue";
import { onBeforeUnmount, onMounted, PropType } from "vue";

import AdChannelIcon from "@/app/ad-channel-icon.vue";
import AnalyticsBlock from "@/app/workspace/campaigns/metrics/block.vue";
import { Campaign } from "@/dtos/campaign.ts";
import { MetricClass } from "@/enums/metric-class.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const emit = defineEmits<{
  (e: "close"): void;
}>();
const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
});
const workspaceStore = useWorkspaceStore();

const emitClose = () => {
  emit("close");
};

onMounted(() => {
  window.addEventListener("keydown", keyDownHandler);
});
onBeforeUnmount(() => {
  window.removeEventListener("keydown", keyDownHandler);
});

const keyDownHandler = (e: any) => {
  const isEscape = e.code === "Escape";

  if (isEscape) {
    emitClose();
  }
};
</script>
<template>
  <div class="fixed inset-0 z-[200] flex grow flex-col items-center bg-black">
    <div class="flex h-16 w-full items-center justify-between pl-9 pr-2.5">
      <div class="flex items-center space-x-4">
        <ad-channel-icon
          :type="props.campaign.ad_channel.type"
          class="h-4 w-4"
        ></ad-channel-icon>
        <span class="opacity-30">{{ props.campaign.name }}</span>
      </div>
      <div>
        <div @click="emitClose" class="group cursor-pointer p-3">
          <Cross1Icon
            class="h-6 w-6 opacity-20 transition-all duration-75 group-hover:opacity-100"
          ></Cross1Icon>
        </div>
      </div>
    </div>
    <div
      class="mt-1.5 grid w-full grow grid-cols-1 gap-7 px-5 sm:px-8 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"
      v-if="workspaceStore.workspace"
    >
      <analytics-block
        name="spend"
        metric="spend"
        :metric-class="MetricClass.CURRENCY"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Return on ad spend"
        metric="roas"
        :metric-class="MetricClass.FLOAT_2DP"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Conversions"
        metric="conversions"
        :metric-class="MetricClass.INTEGER"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Average conversion value"
        metric="avg_conversion_revenue"
        :metric-class="MetricClass.CURRENCY"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Impressions"
        metric="impressions"
        :metric-class="MetricClass.INTEGER"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="People reached"
        metric="reach"
        :metric-class="MetricClass.INTEGER"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Clicks (unique)"
        metric="unique_clicks"
        :metric-class="MetricClass.INTEGER"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Revenue"
        metric="revenue"
        :metric-class="MetricClass.CURRENCY"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Impressions per person"
        metric="impressions_per_person"
        :metric-class="MetricClass.FLOAT_2DP"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Click rate (unique)"
        metric="unique_ctr"
        :metric-class="MetricClass.PERCENT"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Post-click conversion rate"
        metric="unique_click_conversion_rate"
        :metric-class="MetricClass.PERCENT"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="End-to-end conversion rate"
        metric="unique_conversion_rate"
        :metric-class="MetricClass.PERCENT"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Cost per 1000 impressions"
        metric="cpm"
        :metric-class="MetricClass.CURRENCY"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Cost per 1000 reached"
        metric="unique_cpm"
        :metric-class="MetricClass.CURRENCY"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Cost per click (unique)"
        metric="unique_cpc"
        :metric-class="MetricClass.CURRENCY"
        :campaign="props.campaign"
      >
      </analytics-block>
      <analytics-block
        name="Cost per purchase"
        metric="cpp"
        :metric-class="MetricClass.CURRENCY"
        :campaign="props.campaign"
      >
      </analytics-block>
    </div>
  </div>
</template>
