export const connectionAbortedError = () => {
  window.dispatchEvent(new CustomEvent("connection-aborted-error"));
};
export const connectionTimeoutError = () => {
  window.dispatchEvent(new CustomEvent("connection-timeout-error"));
};
export const connectionError = () => {
  window.dispatchEvent(new CustomEvent("connection-error"));
};
export const serverError = (message?: string | undefined) => {
  window.dispatchEvent(
    new CustomEvent("server-error", {
      detail: {
        message,
      },
    }),
  );
};
export const clientError = (message?: string | undefined) => {
  window.dispatchEvent(
    new CustomEvent("client-error", {
      detail: {
        message,
      },
    }),
  );
};
export const userError = (
  title?: string | undefined,
  message?: string | undefined,
) => {
  window.dispatchEvent(
    new CustomEvent("user-error", {
      detail: {
        title,
        message,
      },
    }),
  );
};
