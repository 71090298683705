<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import Toast from "@/components/toast.vue";
import { ToastVariant } from "@/enums/toast-variant.ts";

onMounted(() => {
  window.addEventListener(`connection-timeout-error`, show);
});
onBeforeUnmount(() => {
  window.removeEventListener(`connection-timeout-error`, show);
});

const route = useRoute();

const show = () => {
  if (route.matched[0].name === "home") {
    return;
  }
  open.value = true;
};

const open = ref(false);
</script>
<template>
  <toast
    v-model="open"
    :variant="ToastVariant.ERROR"
    title="Slow connection"
    description="Connection to our server took too long. Please check your internet connection and try again."
    short-description="Please check your internet connection and try again."
  ></toast>
</template>
