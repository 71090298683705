<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      The dashboard is your main screen in Charter where you can see your
      campaigns and get a quick overview of how your ads are doing.
    </p>
    <p>[Screenshot of dashboard with annotations]</p>
  </div>
</template>
