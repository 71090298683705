export const loadMetaPixel = async (id: string, locale: string = "en_GB") => {
  if (document.getElementById("fbevents")) {
    return;
  }

  const fjs = document.getElementsByTagName("script")[0];
  const js = document.createElement("script");

  js.id = "fbevents";
  js.text = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/${locale}/fbevents.js');
fbq('init', '${id}');
fbq('track', 'PageView');`;
  fjs.parentNode?.insertBefore(js, fjs);
};
