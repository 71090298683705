<script setup lang="ts"></script>
<template>
  <svg
    width="33"
    height="26"
    viewBox="0 0 33 26"
    class="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.71595 7.74676L28.3714 0.872409C29.2345 0.617712 30.855 0.403185 31.9784 0.951727C32.8997 1.40157 31.9749 2.38987 30.4559 3.10269L14.9067 9.99938C11.5727 11.7073 8.93209 12.1187 9.1179 16.1417C9.58231 26.1962 9.47327 27.3173 7.07352 24.0094C5.18066 20.5658 1.25548 13.158 0.697715 11.0764C0.139946 8.99477 1.8108 7.9893 2.71595 7.74676Z"
    />
  </svg>
</template>
