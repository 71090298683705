<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      Each account receives a 1 month free trial, with no upfront card details
      needed.
    </p>
    <p>
      Charter’s billing period runs by calendar month regardless of when you
      first created your account. All users are charged for 1 month’s usage on
      the 1st day of the proceeding month.
    </p>
    <p>
      For example, you created an account with Charter on 15th November. Your
      free trial ended on 15th December, and you were billed for your usage
      between 16th - 31st December on 1st January.
    </p>
    <p>
      Charter’s pricing is based on the number of ad channels you have connected
      and is calculated based on usage so you only pay for what you use. Each ad
      channel you connect is $49/month, but if you only use an ad channel for
      half a month, you’ll be charged half.
    </p>
    <p>
      For example, you connected 3 ad channels on 1st December, 2 of them you
      connected for the whole month and 1 of them you only connected for a
      quarter of the month. You’d be charged $49 x 2 + $49 x 0.25 = $110.25.
    </p>
    <p>
      In the billing and payments [linked] section of your account settings,
      you’ll find estimated charges. Charter calculates your usage throughout
      the month in order to give you an idea of what to expect when you’re
      charged. Please note these are estimates only, the amount you are charged
      will be finalised at the end of the billing period.
    </p>
    <p>
      Also in billing and payments [linked] you’ll be able to find invoices and
      receipts for each month you are billed.
    </p>
  </div>
</template>
