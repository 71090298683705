<script setup lang="ts">
import { onMounted, ref } from "vue";

import LoadingBar from "@/components/loading-bar.vue";
import { api } from "@/helpers/api.ts";

type OPTResponse = {
  fb_colin: string;
};

const otp = ref<OPTResponse | undefined>();

const fetchOTP = async () => {
  const response = (await api.get("otp")) as unknown;
  otp.value = response as OPTResponse;
};
onMounted(() => {
  fetchOTP();
  setInterval(fetchOTP, 3000);
});
</script>
<template>
  <div class="flex grow items-center justify-center text-3xl">
    <div v-if="otp" class="flex flex-col items-center justify-center space-y-4">
      <div>
        <span>Facebook (contact@colinsouth.com)</span>
        <span>{{ otp.fb_colin }}</span>
      </div>
    </div>
    <loading-bar v-else></loading-bar>
  </div>
</template>
