<script setup lang="ts">
import { useCookiebot } from "@ambitiondev/vue-cookiebot";
import { onMounted, ref } from "vue";

const { cookieDeclaration } = useCookiebot();

const cookieDeclarationRef = ref<HTMLDivElement | null>(null);

onMounted(() => {
  cookieDeclaration(cookieDeclarationRef);
});
</script>
<template>
  <div class="prose prose-base dark:prose-invert">
    <p class="text-sm">Last Updated: 30th May 2024</p>

    <p>
      This privacy policy sets out how PRECISION ADTECH LTD, trading as Charter,
      uses and protects your personal data.
    </p>

    <ol>
      <li>IMPORTANT INFORMATION AND WHO WE ARE (Paragraph 1)</li>
      <li>TYPES OF PERSONAL DATA WE COLLECT ABOUT YOU (Paragraph 2)</li>
      <li>HOW IS YOUR PERSONAL DATA COLLECTED? (Paragraph 3)</li>
      <li>HOW WE USE YOUR PERSONAL DATA (Paragraph 4)</li>
      <li>DISCLOSURES OF YOUR PERSONAL DATA (Paragraph 5)</li>
      <li>INTERNATIONAL TRANSFERS (Paragraph 6)</li>
      <li>DATA SECURITY (Paragraph 7)</li>
      <li>DATA RETENTION (Paragraph 8)</li>
      <li>YOUR LEGAL RIGHTS (Paragraph 9)</li>
      <li>CONTACT DETAILS (Paragraph 10)</li>
      <li>COMPLAINTS (Paragraph 11)</li>
      <li>
        CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US OF CHANGES
        (Paragraph 12)
      </li>
      <li>THIRD PARTY LINKS (Paragraph 13)</li>
    </ol>

    <h3>1. IMPORTANT INFORMATION AND WHO WE ARE</h3>
    <h3>Privacy policy</h3>
    <p>
      This privacy policy gives you information about how PRECISION ADTECH LTD,
      trading as Charter, collects and uses your personal data through your use
      of this website and our app, including any data you may provide when you
      register with us OR sign up to our newsletter OR purchase a product or
      service OR take part in a competition.
    </p>
    <p>
      This website and our app are not intended for children and we do not
      knowingly collect data relating to children.
    </p>
    <h3>Controller</h3>
    <p>
      PRECISION ADTECH LTD, trading as Charter, is the controller and
      responsible for your personal data (collectively referred to as "COMPANY",
      "we", "us" or "our" in this privacy policy).
    </p>

    <h3>2. THE TYPES OF PERSONAL DATA WE COLLECT ABOUT YOU</h3>
    <p>
      Personal data means any information about an individual from which that
      person can be identified.
    </p>
    <p>
      We may collect, use, store and transfer different kinds of personal data
      about you which we have grouped together as follows:
    </p>
    <ol>
      <li>
        Identity Data includes first name, last name, any previous names,
        username or similar identifier, marital status, title, date of birth and
        gender.
      </li>
      <li>
        Contact Data includes billing address, delivery address, email address
        and telephone numbers.
      </li>
      <li>Financial Data includes bank account and payment card details.</li>
      <li>
        Transaction Data includes details about payments to and from you, other
        details of products and services you have purchased from us and, where
        your business is operating as a sole trader, details about your income.
      </li>
      <li>
        Technical Data includes internet protocol (IP) address, your login data,
        browser type and version, time zone setting and location, browser
        plug-in types and versions, operating system and platform, device ID and
        other technology on the devices you use to access this website or our
        app.
      </li>
      <li>
        Profile Data includes your username and password, purchases or orders
        made by you, your interests, preferences, feedback and survey responses.
      </li>
      <li>
        Usage Data includes information about how you interact with and use our
        website, app, products and services.
      </li>
      <li>
        Marketing and Communications Data includes your preferences in receiving
        marketing from us and our third parties and your communication
        preferences.
      </li>
    </ol>
    <p>
      We also collect, use and share
      <span class="font-black">aggregated data</span> such as statistical or
      demographic data which is not personal data as it does not directly (or
      indirectly) reveal your identity. For example, we may aggregate
      individuals' Usage Data to calculate the percentage of users accessing a
      specific website or app feature in order to analyse general trends in how
      users are interacting with our website or app to help improve the website
      or our app and our service offering.
    </p>

    <h3>3. HOW IS YOUR PERSONAL DATA COLLECTED?</h3>
    <p>
      We use different methods to collect data from and about you including
      through:
    </p>
    <ol>
      <li>
        <span class="font-black">Your interactions with us.</span> You may give
        us your personal data by filling in online forms or by corresponding
        with us by post, phone, email or otherwise. This includes personal data
        you provide when you:
      </li>
      <ol type="a">
        <li>apply for our products or services;</li>
        <li>create an account on our website or app;</li>
        <li>subscribe to our service or publications;</li>
        <li>request marketing to be sent to you;</li>
        <li>enter a competition, promotion or survey; or</li>
        <li>give us feedback or contact us.</li>
      </ol>
      <li>
        <span class="font-black">Automated technologies or interactions.</span>
        As you interact with our website or app, we will automatically collect
        Technical Data about your equipment, browsing actions and patterns. We
        collect this personal data by using cookies, server logs and other
        similar technologies. We may also receive Technical Data about you if
        you visit other websites employing our cookies. Please see our cookie
        policy below for further details.
      </li>
      <li>
        <span class="font-black"
          >Third parties or publicly available sources.</span
        >
        We will receive personal data about you from various third parties and
        public sources as set out below:
      </li>
      <ol type="a">
        <li>Technical Data is collected from the following parties:</li>
        <ol type="i">
          <li>analytics providers;</li>
          <li>advertising networks; and</li>
          <li>search information providers.</li>
        </ol>
        <li>
          Contact, Financial and Transaction Data is collected from providers of
          technical, payment and delivery services.
        </li>
        <li>
          Identity and Contact Data is collected from data brokers or
          aggregators.
        </li>
        <li>
          Identity and Contact Data is collected from publicly available sources
          such as Companies House and the Electoral Register based inside the
          UK.
        </li>
      </ol>
    </ol>

    <h3>4. HOW WE USE YOUR PERSONAL DATA</h3>
    <h3>LEGAL BASIS</h3>
    <p>
      The law requires us to have a legal basis for collecting and using your
      personal data. We rely on one or more of the following legal bases:
    </p>
    <ol>
      <li>
        <span class="font-black">Performance of a contract with you:</span>
        Where we need to perform the contract we are about to enter into or have
        entered into with you.
      </li>
      <li>
        <span class="font-black">Legitimate interests:</span> We may use your
        personal data where it is necessary to conduct our business and pursue
        our legitimate interests, for example to prevent fraud and enable us to
        give you the best and most secure customer experience. We make sure we
        consider and balance any potential impact on you and your rights (both
        positive and negative) before we process your personal data for our
        legitimate interests. We do not use your personal data for activities
        where our interests are overridden by the impact on you (unless we have
        your consent or are otherwise required or permitted to by law).
      </li>
      <li>
        <span class="font-black">Legal obligation:</span> We may use your
        personal data where it is necessary for compliance with a legal
        obligation that we are subject to. We will identify the relevant legal
        obligation when we rely on this legal basis.
      </li>
      <li>
        <span class="font-black">Consent:</span> We rely on consent only where
        we have obtained your active agreement to use your personal data for a
        specified purpose, for example if you subscribe to an email newsletter.
      </li>
    </ol>
    <h3>PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</h3>
    <p>
      We have set out below, in a table format, a description of all the ways we
      plan to use the various categories of your personal data, and which of the
      legal bases we rely on to do so. We have also identified what our
      legitimate interests are where appropriate.
    </p>
    <table>
      <thead>
        <tr>
          <th>Purpose/Use</th>
          <th>Type of data</th>
          <th>Legal basis</th>
        </tr>
      </thead>
      >
      <tbody>
        <tr>
          <td>To register you as a new customer</td>
          <td>
            <ol type="a">
              <li>Identity</li>
              <li>Contact</li>
            </ol>
          </td>
          <td>Performance of a contract with you</td>
        </tr>
        <tr>
          <td>
            To process and deliver your order including:
            <ol type="a">
              <li>
                Notifying you about changes to our terms or privacy policy
              </li>
              <li>Dealing with your requests, complaints and queries</li>
            </ol>
          </td>
          <td>
            <ol type="a">
              <li>Identity</li>
              <li>Contact</li>
              <li>Profile</li>
              <li>Marketing and Communications</li>
            </ol>
          </td>
          <td>
            <ol type="a">
              <li>Performance of a contract with you</li>
              <li>Necessary to comply with a legal obligation</li>
              <li>
                Necessary for our legitimate interests (to keep our records
                updated and manage our relationship with you
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            To enable you to partake in a prize draw, competition or complete a
            survey
          </td>
          <td>
            <ol type="a">
              <li>Identity</li>
              <li>Contact</li>
              <li>Profile</li>
              <li>Usage</li>
              <li>Marketing and Communications</li>
            </ol>
          </td>
          <td>
            <ol type="a">
              <li>Performance of a contract with you</li>
              <li>
                Necessary for our legitimate interests (to study how customers
                use our products/services, to develop them and grow our
                business)
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            To administer and protect our business, this website and our app
            (including troubleshooting, data analysis, testing, system
            maintenance, support, reporting and hosting of data)
          </td>
          <td>
            <ol type="a">
              <li>Identity</li>
              <li>Contact</li>
              <li>Technical</li>
            </ol>
          </td>
          <td>
            <ol type="a">
              <li>
                Necessary for our legitimate interests (for running our
                business, provision of administration and IT services, network
                security, to prevent fraud and in the context of a business
                reorganisation or group restructuring exercise)
              </li>
              <li>Necessary to comply with a legal obligation</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>
            To deliver relevant website and app content and online
            advertisements to you and measure or understand the effectiveness of
            the advertising we serve to you
          </td>
          <td>
            <ol type="a">
              <li>Identity</li>
              <li>Contact</li>
              <li>Profile</li>
              <li>Usage</li>
              <li>Marketing and Communications</li>
              <li>Technical</li>
            </ol>
          </td>
          <td>
            Necessary for our legitimate interests (to study how customers use
            our products/services, to develop them, to grow our business and to
            inform our marketing strategy)
          </td>
        </tr>
        <tr>
          <td>
            To use data analytics to improve our website, app,
            products/services, customer relationships and experiences and to
            measure the effectiveness of our communications and marketing
          </td>
          <td>
            <ol type="a">
              <li>Technical</li>
              <li>Usage</li>
            </ol>
          </td>
          <td>
            Necessary for our legitimate interests (to define types of customers
            for our products and services, to keep our website and app updated
            and relevant, to develop our business and to inform our marketing
            strategy)
          </td>
        </tr>
        <tr>
          <td>
            To send you relevant marketing communications and make personalised
            suggestions and recommendations to you about goods or services that
            may be of interest to you based on your Profile Data
          </td>
          <td>
            <ol type="a">
              <li>Identity</li>
              <li>Contact</li>
              <li>Technical</li>
              <li>Usage</li>
              <li>Profile</li>
              <li>Marketing and Communications</li>
            </ol>
          </td>
          <td>
            Necessary for our legitimate interests (to carry out direct
            marketing, develop our products/services and grow our business) OR
            Consent, having obtained your prior consent to receiving direct
            marketing communications
          </td>
        </tr>
        <tr>
          <td>
            To carry out market research through your voluntary participation in
            surveys
          </td>
          <td></td>
          <td>
            Necessary for our legitimate interests (to study how customers use
            our products/services and to help us improve and develop our
            products and services).
          </td>
        </tr>
      </tbody>
    </table>

    <h3>DIRECT MARKETING</h3>
    <p>
      During the registration process on our website or app when your personal
      data is collected, you will be asked to indicate your preferences for
      receiving direct marketing communications from PRECISION ADTECH LTD
      trading as Charter, via EMAIL.
    </p>
    <p>
      We may also analyse your Identity, Contact, Technical, Usage and Profile
      Data to form a view which products, services and offers may be of interest
      to you so that we can then send you relevant marketing communications.
    </p>

    <h3>THIRD-PARTY MARKETING</h3>
    <p>
      We will get your express consent before we share your personal data with
      any third party for their own direct marketing purposes.
    </p>

    <h3>OPTING OUT OF MARKETING</h3>
    <p>
      You can ask to stop sending you marketing communications at any time by
      following the opt-out links within any marketing communication sent to you
      or by contacting us at
      <a href="mailto:legal@usecharter.io">legal@usecharter.io</a>.
    </p>
    <p>
      If you opt out of receiving marketing communications, you will still
      receive service-related communications that are essential for
      administrative or customer service purposes for example updates to our
      Terms and Conditions or checking that your contact details are correct.
    </p>

    <h3>COOKIES</h3>
    <p>
      This website uses cookies. We use cookies to personalise content and ads,
      to provide social media features and to analyse our traffic. We also share
      information about your use of our site with our social media, advertising
      and analytics partners who may combine it with other information that
      you've provided to them or that they've collected from your use of their
      services.
    </p>
    <p>
      Cookies are small text files that can be used by websites to make a user's
      experience more efficient.
    </p>
    <p>
      The law states that we can store cookies on your device if they are
      strictly necessary for the operation of this site. For all other types of
      cookies we need your permission.
    </p>
    <p>
      This site uses different types of cookies. Some cookies are placed by
      third party services that appear on our pages.
    </p>
    <p>
      You can at any time change or withdraw your consent from the Cookie
      Declaration on our website.
    </p>
    <p>
      Learn more about who we are, how you can contact us and how we process
      personal data in this Privacy Policy.
    </p>
    <p>
      Please state your consent ID and date when you contact us regarding your
      consent.
    </p>

    <h3>5. DISCLOSURES OF YOUR PERSONAL DATA</h3>
    <p>
      We may share your personal data where necessary with the parties set out
      below for the purposes set out in the table
      <span class="italic"
        >Purposes for which we will use your personal data</span
      >
      above.
    </p>
    <ol>
      <li>PostHog</li>
      <li>
        Third parties to whom we may choose to sell, transfer or merge parts of
        our business or our assets. Alternatively, we may seek to acquire other
        businesses or merge with them. If a change happens to our business, then
        the new owners may use your personal data in the same way as set out in
        this privacy policy.
      </li>
    </ol>
    <p>
      We require all third parties to respect the security of your personal data
      and to treat it in accordance with the law. We do not allow our
      third-party service providers to use your personal data for their own
      purposes and only permit them to process your personal data for specified
      purposes and in accordance with our instructions.
    </p>

    <h3>6. INTERNATIONAL TRANSFERS</h3>
    <p>
      We may transfer your personal data to service providers that carry out
      certain functions on our behalf. This may involve transferring personal
      data outside the UK to countries which have laws that do not provide the
      same level of data protection as the UK law.
    </p>
    <p>
      Whenever we transfer your personal data out of the UK to service
      providers, we ensure a similar degree of protection is afforded to it by
      ensuring that the following safeguards are in place:
    </p>
    <ol>
      <li>
        We will only transfer your personal data to countries that have been
        deemed by the UK to provide an adequate level of protection for personal
        data, namely, the Netherlands (The EU General Data Protection Regulation
        (GDPR)) OR
      </li>
      <li>
        We may use specific standard contractual terms approved for use in the
        UK which give the transferred personal data the same protection as it
        has in the UK, namely the International Data Transfer Agreement OR The
        International Data Transfer Addendum to the European Commission’s
        standard contractual clauses for international data transfers. To obtain
        a copy of these contractual safeguards, please contact us at
        <a href="mailto:legal@usecharter.io">legal@usecharter.io</a>
      </li>
    </ol>

    <h3>7. DATA SECURITY</h3>
    <p>
      We have put in place appropriate security measures to prevent your
      personal data from being accidentally lost, used or accessed in an
      unauthorised way, altered or disclosed. In addition, we limit access to
      your personal data to those employees, agents, contractors and other third
      parties who have a business need to know. They will only process your
      personal data on our instructions and they are subject to a duty of
      confidentiality.
    </p>
    <p>
      We have put in place procedures to deal with any suspected personal data
      breach and will notify you and any applicable regulator of a breach where
      we are legally required to do so.
    </p>

    <h3>8. DATA RETENTION</h3>
    <h3>HOW LONG WILL YOU USE MY PERSONAL DATA FOR?</h3>
    <p>
      We will only retain your personal data for as long as reasonably necessary
      to fulfil the purposes we collected it for, including for the purposes of
      satisfying any legal, regulatory, tax, accounting or reporting
      requirements. We may retain your personal data for a longer period in the
      event of a complaint or if we reasonably believe there is a prospect of
      litigation in respect to our relationship with you.
    </p>
    <p>
      To determine the appropriate retention period for personal data, we
      consider the amount, nature and sensitivity of the personal data, the
      potential risk of harm from unauthorised use or disclosure of your
      personal data, the purposes for which we process your personal data and
      whether we can achieve those purposes through other means, and the
      applicable legal, regulatory, tax, accounting or other requirements.
    </p>
    <p>
      By law we have to keep basic information about our customers (including
      Contact, Identity, Financial and Transaction Data) for six years after
      they cease being customers for tax purposes.
    </p>
    <p>
      In some circumstances you can ask us to delete your data: see Paragraph 9
      below for further information.
    </p>
    <p>
      In some circumstances we will anonymise your personal data (so that it can
      no longer be associated with you) for research or statistical purposes, in
      which case we may use this information indefinitely without further notice
      to you.
    </p>

    <h3>9. YOUR LEGAL RIGHTS</h3>
    <p>
      You have a number of rights under data protection laws in relation to your
      personal data.
    </p>
    <p>You have the right to:</p>
    <ol>
      <li>
        Request access to your personal data (commonly known as a "subject
        access request"). This enables you to receive a copy of the personal
        data we hold about you and to check that we are lawfully processing it.
      </li>
      <li>
        Request correction of the personal data that we hold about you. This
        enables you to have any incomplete or inaccurate data we hold about you
        corrected, though we may need to verify the accuracy of the new data you
        provide to us.
      </li>
      <li>
        Request erasure of your personal data in certain circumstances. This
        enables you to ask us to delete or remove personal data where there is
        no good reason for us continuing to process it. You also have the right
        to ask us to delete or remove your personal data where you have
        successfully exercised your right to object to processing (see below),
        where we may have processed your information unlawfully or where we are
        required to erase your personal data to comply with local law. Note,
        however, that we may not always be able to comply with your request of
        erasure for specific legal reasons which will be notified to you, if
        applicable, at the time of your request.
      </li>
      <li>
        Object to processing of your personal data where we are relying on a
        legitimate interest (or those of a third party) as the legal basis for
        that particular use of your data (including carrying out profiling based
        on our legitimate interests). In some cases, we may demonstrate that we
        have compelling legitimate grounds to process your information which
        override your right to object.
      </li>
      <li>
        You also have the absolute right to object any time to the processing of
        your personal data for direct marketing purposes (see OPTING OUT OF
        MARKETING in Paragraph 4 for details of how to object to receiving
        direct marketing communications).
      </li>
      <li>
        Request the transfer of your personal data to you or to a third party.
        We will provide to you, or a third party you have chosen, your personal
        data in a structured, commonly used, machine-readable format. Note that
        this right only applies to automated information which you initially
        provided consent for us to use or where we used the information to
        perform a contract with you.
      </li>
      <li>
        Withdraw consent at any time where we are relying on consent to process
        your personal data (see the table in section 4 for details of when we
        rely on your consent as the legal basis for using your data). However,
        this will not affect the lawfulness of any processing carried out before
        you withdraw your consent. If you withdraw your consent, we may not be
        able to provide certain products or services to you. We will advise you
        if this is the case at the time you withdraw your consent.
      </li>
      <li>
        Request restriction of processing of your personal data. This enables
        you to ask us to suspend the processing of your personal data in one of
        the following scenarios:
        <ol type="a">
          <li>If you want us to establish the data's accuracy;</li>
          <li>
            Where our use of the data is unlawful but you do not want us to
            erase it;
          </li>
          <li>
            Where you need us to hold the data even if we no longer require it
            as you need it to establish, exercise or defend legal claims; or
          </li>
          <li>
            You have objected to our use of your data but we need to verify
            whether we have overriding legitimate grounds to use it.
          </li>
        </ol>
      </li>
      <li>
        If you wish to exercise any of the rights set out above, please contact
        us, see Contact details (Paragraph 10).
      </li>
    </ol>
    <h3>NO FEE USUALLY REQUIRED</h3>
    <p>
      You will not have to pay a fee to access your personal data (or to
      exercise any of the other rights). However, we may charge a reasonable fee
      if your request is clearly unfounded, repetitive or excessive.
      Alternatively, we could refuse to comply with your request in these
      circumstances.
    </p>
    <h3>WHAT WE MAY NEED FROM YOU</h3>
    <p>
      We may need to request specific information from you to help us confirm
      your identity and ensure your right to access your personal data (or to
      exercise any of your other rights). This is a security measure to ensure
      that personal data is not disclosed to any person who has no right to
      receive it. We may also contact you to ask you for further information in
      relation to your request to speed up our response.
    </p>
    <h3>TIME LIMIT TO RESPOND</h3>
    <p>
      We try to respond to all legitimate requests within one month.
      Occasionally it could take us longer than a month if your request is
      particularly complex or you have made a number of requests. In this case,
      we will notify you and keep you updated.
    </p>

    <h3>10. CONTACT DETAILS</h3>
    <p>
      If you have any questions about this privacy policy or about the use of
      your personal data or you want to exercise your privacy rights, please
      contact us via email:
    </p>
    <p>
      Email address:
      <a href="mailto:legal@usecharter.io">legal@usecharter.io</a>
    </p>

    <h3>11. COMPLAINTS</h3>
    <p>
      You have the right to make a complaint at any time to the Information
      Commissioner’s Office (ICO), the UK regulator for data protection issues
      (<a href="https://www.ico.org.uk">www.ico.org.uk</a>). We would, however,
      appreciate the chance to deal with your concerns before you approach the
      ICO so please contact us in the first instance.
    </p>

    <h3>
      12. CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US OF CHANGES
    </h3>
    <p>
      We keep our privacy policy under regular review. This version was last
      updated on 30th May 2024.
    </p>
    <p>
      It is important that the personal data we hold about you is accurate and
      current. Please keep us informed if your personal data changes during your
      relationship with us, for example a new address or email address.
    </p>

    <h3>13. THIRD-PARTY LINKS</h3>
    <p>
      This website may include links to third-party websites, plug-ins and
      applications. Clicking on those links or enabling those connections may
      allow third parties to collect or share data about you. We do not control
      these third-party websites and are not responsible for their privacy
      statements. When you leave our website, we encourage you to read the
      privacy policy of every website you visit.
    </p>
  </div>
</template>
