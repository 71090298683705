<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";

import Toast from "@/components/toast.vue";
import { ToastVariant } from "@/enums/toast-variant.ts";

onMounted(() => {
  window.addEventListener(`client-error`, show);
});
onBeforeUnmount(() => {
  window.removeEventListener(`client-error`, show);
});

const show = (e: any) => {
  message.value = e.detail.message;
  open.value = true;
};

const open = ref(false);
const message = ref<string | undefined>();
</script>
<template>
  <toast
    v-model="open"
    :variant="ToastVariant.ERROR"
    title="Something unexpected happened"
    :description="
      message ||
      `Our developers have been notified. If problems continues, we are really sorry! It will be resolved soon.`
    "
    :short-description="
      message ??
      `Something unexpected happened, our developers have been notified.`
    "
  ></toast>
</template>
