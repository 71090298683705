<script setup lang="ts"></script>
<template>
  <svg viewBox="50 60 400 400" xmlns="http://www.w3.org/2000/svg">
    <path
      style="fill: #00f6ef"
      d="M415.27,214.69V146.55c-89.08-3.75-91.89-84.69-92-92.3v-.57H254.49V319.06h0a54.5,54.5,0,1,1-38.64-52.18V196.59A123.49,123.49,0,1,0,323.43,319.06c0-1.73-.05-3.45-.12-5.16V183.22C355,212.22,415.27,214.69,415.27,214.69Z"
    />
    <path
      style="fill: #ffffff"
      d="M435.54,231.47V163.33c-89.08-3.76-91.89-84.7-92-92.3v-.58H274.76V335.84h0a54.54,54.54,0,1,1-38.64-52.18V213.37A123.49,123.49,0,1,0,343.7,335.84c0-1.73-.05-3.45-.12-5.16V200C375.28,229,435.54,231.47,435.54,231.47Z"
    />
    <path
      style="fill: #ff004f"
      d="M325,70.45c3.32,18.07,13,46.34,41.28,62.87-21.6-25.15-22.7-57.61-22.74-62.29v-.58Z"
    />
    <path
      style="fill: #ff004f"
      d="M435.54,231.47V163.33a127.94,127.94,0,0,1-20.27-2.42v53.66s-60.26-2.47-92-31.46V313.78c.07,1.72.12,3.43.12,5.16a123.49,123.49,0,0,1-191.9,102.81A123.48,123.48,0,0,0,343.7,335.84c0-1.73-.05-3.45-.12-5.16V200C375.28,229,435.54,231.47,435.54,231.47Z"
    />
    <path
      style="fill: #ff004f"
      d="M215.85,266.76a54.56,54.56,0,0,0-39.63,101.31,54.54,54.54,0,0,1,59.9-84.41V213.37a124.69,124.69,0,0,0-15.91-1c-1.46,0-2.91,0-4.36.09Z"
    />
  </svg>
</template>
