<script setup lang="ts">
import { computed, PropType } from "vue";

import { BadgeState } from "@/enums/badge-state.ts";

const props = defineProps({
  state: {
    type: String as PropType<BadgeState>,
    required: true,
  },
});

const styling = computed(() => {
  switch (props.state) {
    case BadgeState.POSITIVE:
      return "bg-emerald-950 text-emerald-400";
    case BadgeState.WARNING:
      return "bg-amber-950 text-amber-500";
    case BadgeState.NEGATIVE:
      return "text-[#ff6991]";
    case BadgeState.PENDING:
      return "bg-amber-200 text-amber-800";
  }
  return "bg-white/8 text-white/50";
});
</script>
<template>
  <div
    :class="`flex h-[22px] w-auto select-none items-center justify-center space-x-2.5 whitespace-nowrap rounded-full px-5 text-sm ${styling}`"
  >
    <slot></slot>
  </div>
</template>
